<div>

  <div class="sql-instructions">
    <p>
      Please create a custom WHERE clause below. For example:
    </p>
    <ul class="example">
      <li>
        <div class="boxed-text example-text">
          WHERE 1=1<br/>AND li.storeId = '999'
        </div>
      </li>
    </ul>
    <p>
      Your SQL may make reference to the following table aliases:
    </p>
    <ul class="table-aliases">
      <li><span>li</span> - Refers to the <em>LineItem</em> table</li>
      <li><span>prod</span> - Refers to the <em>Product</em> table</li>
    </ul>
  </div>

  <div class="locked-prepend-sql">
    SELECT [available-fields]<br/>
    FROM [available-retailer-tables]
  </div>

  <siq-sql-input-box
    [initQuery]="initQuery"
    [(query)]="form.value.sql"
    (onDryRun)="onDryRun($event)"
    (queryChange)="queryChange($event)"
    [sqlEditorService]="sqlEditorService"
    [prependSql]="sqlPrependBE"
    sqlTheme="monokai"
  ></siq-sql-input-box>

</div>
