import { User } from 'app/user/models/user.model';
import { AccessGroupDTO, AccessGroupFormValues } from 'app/access-group/models/interfaces';
import { FilterSelection } from 'app/filter/models/filter-selection';
import { BaseModel } from 'app/core/models/base-model';
import * as _ from 'lodash';

export enum AccessGroupStatus {
  READY = 'READY',
  RUNNING = 'RUNNING',
  ERROR = 'ERROR'
}

export class AccessGroup extends BaseModel implements AccessGroupDTO {
  description: string = null;
  displayName: string = null;
  filters: FilterSelection[];
  formValues: AccessGroupFormValues;
  id: number = null;
  retailers: string[] = [];
  sql: string = null;
  status: AccessGroupStatus = AccessGroupStatus.RUNNING;
  usersGroup: User[] = [];
  deleteReports?: boolean; // Added in AG-145

  constructor(accessGroup?: AccessGroupDTO) {
    super();
    if (accessGroup) {
      Object.keys(accessGroup).map(k => {
        this[k] = accessGroup[k];
      });
      if (_.get(accessGroup, 'formValues.filters')) {
        this.filters = JSON.parse(accessGroup.formValues.filters);
      }
    }
  }

  getDescription(): string {
    return this.description;
  }

  setDescription(description: string): void {
    this.description = description;
  }

  getDisplayName(): string {
    return this.displayName;
  }

  setDisplayName(displayName: string): void {
    this.displayName = _.trim(displayName);
  }

  getFilters(): FilterSelection[] {
    return this.filters || <FilterSelection[]>[];
  }

  setFilters(filters: FilterSelection[]): void {
    this.filters = filters;
  }

  getFormValues(): AccessGroupFormValues {
    return _.isNil(this.formValues) ? null : this.formValues;
  }

  setFormValues(formValues: AccessGroupFormValues): void {
    this.formValues = formValues;
  }

  getId(): number {
    return this.id;
  }

  getRetailers(): string[] {
    return this.retailers;
  }

  setRetailers(retailers: string[]): void {
    this.retailers = retailers;
  }

  getSql(): string {
    return this.sql;
  }

  setSql(sql: string): void {
    this.sql = sql;
  }

  getUsersGroup(): User[] {
    return this.usersGroup;
  }

  setUsersGroup(users: User[]): void {
    this.usersGroup = users;
  }

  getStatus(): AccessGroupStatus {
    return this.status;
  }
}
