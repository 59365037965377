import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SqlEditorService } from 'app/siq-applications/modules/temp/sql-editor.service';
import { Subject } from 'rxjs';
import { debounceTime, take } from 'rxjs';
import { EnvConfig, EnvConfigService } from 'app/core/services/env-config/env-config.service';
import { AccessGroup } from 'app/access-group/models/access-group.model';
import { UntypedFormGroup } from '@angular/forms';
import { BaseSiqComponent } from '@siq-js/angular-buildable-lib';

@Component({
  selector: 'siq-access-group-sql',
  templateUrl: './access-group-sql.component.html',
  styleUrls: ['./access-group-sql.component.scss']
})
export class AccessGroupSqlComponent extends BaseSiqComponent implements OnInit {
  @Input() accessGroup: AccessGroup;
  @Input() form: UntypedFormGroup;
  @Input() initQuery: string;
  @Output() onSqlValid: EventEmitter<boolean> = new EventEmitter<boolean>();

  public sqlPrepend: string;
  public sqlPrependBE: string;

  private canRun: boolean;
  private sqlDebouncer: Subject<string> = new Subject();
  private sqlDebounceTime = 250;

  constructor(public sqlEditorService: SqlEditorService) {
    super();
    EnvConfigService.data.pipe(
      take(2)
    ).subscribe((envConfig: EnvConfig) => {
      if (envConfig) {
        this.sqlPrependBE = envConfig.accessGroupSql + '\n';
      }
    });
  }

  ngOnInit() {
    this.setSqlDebouncer();
    this.setSqlPrepend();
  }

  public onDryRun(canRun: boolean) {
    this.canRun = canRun;
    this.onSqlValid.emit(canRun);
  }

  public queryChange(query: string): void {
    this.sqlDebouncer.next(query);
  }

  private setSqlDebouncer(): void {
    this.sqlDebouncer.pipe(
      debounceTime(this.sqlDebounceTime)
    ).subscribe(sql => {
      // The query has changed in the sql-input-box (holds Ace editor). Update this form with new value.
      this.updateForm(sql);

      // If the sql is blank, emit TRUE to onSqlValid to enable the Submit button of the overall form to be enabled.
      if (!sql.trim()) {
        this.onSqlValid.emit(true);
      }
    });
  }

  private setSqlPrepend(): void {
    const bt = '`';
    const columns = 'lit.transactionId, lit.productId, lit.storeId, lit.dateCreated, lit.extendedAmount, lit.dateProcessed, lit.quantity';
    const getId = 'ab';
    const retailerId = 'kg';
    const BQ_BASE_DATASET = 'lineitem';
    const BQ_LI_PRODUCT_KEY = 'productId';
    const BQ_LI_TRANSACTION_KEY = 'transactionId';
    const BQ_SIQ_ID_KEY = 'siqID';
    const BQ_UPC_KEY = 'upc';

    this.sqlPrepend = `SELECT ${columns} FROM products.${getId} prod `;
    this.sqlPrepend += `JOIN ${bt}upcMappings.${getId}${bt} main_map ON main_map.${BQ_UPC_KEY} = prod.${BQ_UPC_KEY} `;
    this.sqlPrepend += `JOIN ${bt}upcMappings.ab${bt} ret_map ON ret_map.${BQ_SIQ_ID_KEY} = main_map.${BQ_SIQ_ID_KEY} `;
    this.sqlPrepend += `JOIN ${bt}${BQ_BASE_DATASET}.${retailerId}${bt} li ON li.${BQ_LI_PRODUCT_KEY} = main_map.${BQ_UPC_KEY} `;
    this.sqlPrepend += `RIGHT JOIN ${bt}${BQ_BASE_DATASET}.${retailerId}${bt} lit ON lit.${BQ_LI_TRANSACTION_KEY} = li.${BQ_LI_TRANSACTION_KEY}`;
  }

  private updateForm(sql: string): void {
    this.form.patchValue({
      'sql': sql
    });
  }
}
