import { CmsField } from '@siq-js/cms-lib';
import { QueryModeComponent } from 'app/core/components/query-mode-component/query-mode.component';
import { QueryModeModalConfig } from 'app/core/components/query-mode-modal/query-mode-modal.component';
import { ReportBuilderResultData } from 'app/siq-applications/modules/report-builder/models/results/report-builder-result-data.model';
import { debounceTime, delay, filter, map, mergeMap, take, takeUntil, tap } from 'rxjs';
import { AbstractReportBuilderEntity } from 'app/siq-applications/modules/report-builder/models/abstract-report-builder-entity.model';
import { ActivatedRoute, Router } from '@angular/router';
import { ActivityStatus } from 'app/activity/models/activity.model';
import { ActivityService } from 'app/activity/services/activity.service';
import { AsyncStatusService } from 'app/core/services/async-status/async-status.service';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MixpanelService } from 'app/core/services/mixpanel/mixpanel.service';
import { MixpanelEvent } from 'app/core/services/mixpanel/mixpanel-event.enum';
import { ReportActivity } from 'app/siq-applications/modules/report-builder/models/report-activity.model';
import { ReportBuilderConfig } from 'app/siq-applications/modules/report-builder/models/report-builder-config';
import { ReportBuilderDraft } from 'app/siq-applications/modules/report-builder/models/results/report-builder-draft.model';
import { ReportBuilderFormComponent } from 'app/siq-applications/modules/report-builder/components/report-builder-form/report-builder-form.component';
import { ReportBuilderService } from 'app/siq-applications/modules/report-builder/services/report-builder.service';
import { ReportBuilderSheet } from 'app/siq-applications/modules/report-builder/models/results/report-builder-sheet.model';
import { SharingService } from 'app/activity/modules/sharing/services/sharing.service';
import * as _ from 'lodash';
import { GridConfig, AgGridAngular } from '@siq-js/visual-lib';
import { UtilsService } from 'app/core/services/utils/utils.service';
import { NavSecondaryService } from 'app/core/components/nav-secondary/nav-secondary.service';
import { NotificationService } from '@siq-js/angular-buildable-lib';
import { CloudExportable } from 'app/core/modules/cloud-export/models/cloud-export.interface';
import { CloudExportService } from 'app/core/modules/cloud-export/services/cloud-export.service';
import { BaseSiqComponent } from '@siq-js/angular-buildable-lib';
import { ReportBuilderQueryModeModalComponent } from 'app/siq-applications/modules/report-builder/components/report-builder-query-mode-modal/report-builder-query-mode-modal.component';
import { EnvConfigService } from 'app/core/services/env-config/env-config.service';
import { ReportBuilderGridProcessor } from 'app/siq-applications/modules/report-builder/components/report-builder-result/report-builder-grid-processor';
import { EmitterService } from 'app/core/services/emitter/emitter.service';
import { Location } from '@angular/common';
import { ActivityGridOptionsMenu } from 'app/siq-applications/modules/shared/components/activity-grid-options-menu/activity-grid-options-menu.component';
import { RelativePipe } from 'app/core/pipes/relative.pipe';

@Component({
  selector: 'siq-report-builder-result',
  templateUrl: './report-builder-result.component.html',
  styleUrls: ['./report-builder-result.component.scss']
})
export class ReportBuilderResultComponent extends BaseSiqComponent implements OnInit, OnDestroy, CloudExportable {

  @ViewChild('sheetForm') sheetForm: ReportBuilderFormComponent;
  @ViewChild('sheetNameInput') sheetNameInput: ElementRef;
  @ViewChild('queryMode') queryMode: QueryModeComponent;

  private readonly TOOL_PANEL = 'Tool Panel';
  private readonly DOWNLOAD_CSV = 'Download CSV (Sheet)';
  private readonly DOWNLOAD_EXCEL_SHEET = 'Download Excel (Sheet)';
  private readonly DOWNLOAD_EXCEL_REPORT = 'Download Excel (Report)';
  private readonly CLONE_REPORT = 'Clone Report';
  private readonly DRAFT_ALERT = ActivityStatus.ALERT;

  private startDownloading$: Subject<any>;

  public activeSheetOptionsConfig: string[];
  // If you modify customOptionMenuItems, make sure to modify handleOptionMenuClick() function accordingly
  public customOptionMenuItems: string[] = [
    ActivityGridOptionsMenu.AUTOSIZE_COLUMNS,
    this.TOOL_PANEL,
    this.DOWNLOAD_CSV,
    this.DOWNLOAD_EXCEL_SHEET,
    this.DOWNLOAD_EXCEL_REPORT,
    ActivityGridOptionsMenu.SHARE_SCHEDULE,
    this.CLONE_REPORT
  ];

  public cloneOnlyMenuItems: string[] = [
    /*Temporary hide share/schedule for V2 launch */
    // ActivityGridOptionsMenu.SHARE_SCHEDULE,

    this.CLONE_REPORT
  ];

  public processor = ReportBuilderGridProcessor.processor;
  public gridConfig: GridConfig = {
    tableHeight: '100%'
  };

  public formSchemaController$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  public model: {
    lastSynced: Date;
    numCols?: number;
    numRows?: number;
    sheetName?: string;
    canDelete: boolean;
    syncing: boolean;
  };

  public activeSheet: AbstractReportBuilderEntity;
  public formMode: boolean;
  public readonly: boolean;
  public canDrill: boolean;
  public countDrafts: number;
  public isCloudExportable: boolean;
  public report: ReportActivity;
  public reportName;
  public statusEnums = ActivityStatus;
  public sheets$: Subject<AbstractReportBuilderEntity[]>; // Handles any changes to the report's sheets model

  public queryModeModalConfig: QueryModeModalConfig = {
    component: ReportBuilderQueryModeModalComponent,
    dataGetter: () => this.sheetForm.formData,
    diffFn: schema => this.formMode && !this.sheetForm.formData.isValidSchema(schema)
  };

  public lastSyncedTxt: string;

  // Internal model for tracking all the sheets & drafts in a report. This should be treated as read-only
  // IMPORTANT: do NOT directly push or modify this. All changes should be made through the sheets$ subject above
  public sheets: AbstractReportBuilderEntity[];

  private syncDebouncer$: Subject<boolean>; // Debouncer to prevent multiple sync() calls being fired in a short period of time
  private trackedByMixpanel: any = {};
  private unsubTotals$: Subject<void> = new Subject<void>();

  private NOT_FOUND = 'not-found';

  constructor(
    public config: ReportBuilderConfig,
    public activityService: ActivityService,
    public reportBuilderService: ReportBuilderService,
    public cloudExportService: CloudExportService,
    public route: ActivatedRoute,
    public utils: UtilsService,
    private router: Router,
    private mixpanelService: MixpanelService,
    private asyncStatusService: AsyncStatusService,
    private notificationService: NotificationService,
    private sharingService: SharingService,
    private location: Location
  ) {
    super();
    this.syncDebouncer$ = new Subject<boolean>();
    this.sheets$ = new Subject<AbstractReportBuilderEntity[]>();

    this.formMode = false;
    this.model = {
      syncing: false,
      lastSynced: new Date(),
      canDelete: false
    };
    this.updateLastSyncedTxt();
    // Default values of the Options menu
    this.activeSheetOptionsConfig = this.customOptionMenuItems;
  }

  attachCloudData(sheets: ReportBuilderSheet[]) {
    if (!this.isCloudExportable) return;

    let exported = false;
    // Proceed When all sheets (grids) are ready for export
    combineLatest(
      sheets.map((s: ReportBuilderSheet) => s.readyForExport$)
    )
    .subscribe(async sheetsReady => {

      // Only continue if all elements in arr are true
      if (!sheetsReady.reduce((p, c) => p && c)) return;

      if (exported) return;

      // if a cell threshold is needed again, check commit history of this file and this function to see previous implementation
      this.reportBuilderService.getCloudExportData(this.reportName, sheets).then(data => {
        this.cloudExportService.setData(data);
        exported = true;
      });
    });
  }

  ngOnDestroy() {
    if ( this.activeSheet instanceof ReportBuilderDraft) {
      this.activeSheet.sync();
    }
    this.unsubTotals$.next();
    this.unsubTotals$.complete();
    super.ngOnDestroy();
  }

  async ngOnInit() {

    this.isCloudExportable = await CloudExportService.isExportable(this);

    // Set up the sync debouncer
    this.syncDebouncer$
    .pipe(debounceTime(this.config.syncDebounceTime))
    .subscribe(ready => ready && this.syncReport());

    this.queryMode.schema$
    .pipe(
      filter(schema => {
        // only propagate changes to form if the entire report is in formMode (otherwise ignore)
        // this prevents the hidden form performing unwanted pruning of form data from outside modifications of the schema controller
        return this.formMode;
      }),
      takeUntil(this.unsub$)
    )
    .subscribe(this.formSchemaController$);

    await this.asyncStatusService.isReady({cms: true, envConfig: true});

    this.route.paramMap // check URL
    .pipe(
      mergeMap(params => {
        const reportId = params.get('id'); // Parse report ID

        return this.reportBuilderService.getReport(reportId); // Retrieve report
      })
    )
    .subscribe(report => {
      try {
        this.init(report);

        // Attempt to load state
        if (!this.loadState()) {
          // Check query params for sheet to jump to
          this.parseQueryParams();
        }
      } catch (e) {
        this.redirectToNotFoundAndStopLoading();
      }
    }, error => {
      this.redirectToNotFoundAndStopLoading();
    });

    this.startDownloading$ = new Subject();
    this.startDownloading$.pipe(
      tap(() => { (this.activeSheet as ReportBuilderSheet).gridVisualOptions.apiRef().grid.api.showLoadingOverlay(); }),
      debounceTime(200)
    )
    .subscribe(clicked => {
      let reportName: string = this.reportName.trim() === '' ? 'Untitled Report' : this.reportName.trim();
      let sheetName: string = (this.activeSheet as ReportBuilderSheet).name.trim() === '' ? 'Untitled Sheet' : (this.activeSheet as ReportBuilderSheet).name.trim();
      try {
        switch (clicked) {
          case 'csv':
            this.reportBuilderService.exportSheetAsCSV(reportName + '-' + sheetName, this.activeSheet as ReportBuilderSheet);
            this.mixpanelService.track(MixpanelEvent.REPORTS_EXPORTED, {
              'Application': this.config.getApplication().display,
              'Report ID': this.report.getId(),
              'Report Name': reportName,
              'File Type': 'csv',
              'Export Type': 'sheet'
            });
            break;
          case 'sheet':
            this.reportBuilderService.exportSheetAsExcel(reportName + '-' + sheetName, [this.activeSheet as ReportBuilderSheet]);
            this.mixpanelService.track(MixpanelEvent.REPORTS_EXPORTED, {
              'Application': this.config.getApplication().display,
              'Report ID': this.report.getId(),
              'Report Name': reportName,
              'File Type': 'xlsx',
              'Export Type': 'sheet'
            });
            break;
          case 'report':
            const sheets = this.sheets.filter(s => s instanceof ReportBuilderSheet);
            this.reportBuilderService.exportSheetAsExcel(reportName, sheets as ReportBuilderSheet[]);
            this.mixpanelService.track(MixpanelEvent.REPORTS_EXPORTED, {
              'Application': this.config.getApplication().display,
              'Report ID': this.report.getId(),
              'Report Name': reportName,
              'File Type': 'xlsx',
              'Export Type': 'report'
            });
        }
        (this.activeSheet as ReportBuilderSheet).gridVisualOptions.apiRef().grid.api.hideOverlay();
      } catch (e) {
        console.error(e);
        (this.activeSheet as ReportBuilderSheet).gridVisualOptions.apiRef().grid.api.hideOverlay();
      }
    });
  }

  private redirectToNotFoundAndStopLoading(): void {
    // stop progress bar
    EmitterService.get('topLoading').emit(false);
    // redirect to non-existent url to be get by NotFoundModule module
    this.router.navigate([this.NOT_FOUND], {relativeTo: this.route}).then(value => {
      // replace non-existent url by the old one
      this.location.replaceState(this.router.url.toString().replace('/' + this.NOT_FOUND, ''));
    });
  }

  // Sets the active sheet to be rendered
  public setActiveSheet(sheet: AbstractReportBuilderEntity, formMode?: boolean, suppressSync?: boolean) {
    NavSecondaryService.close();
    if (_.get(this.sheetForm, 'model.running')) {
      // Disable rerouting to new sheets while a sheet is still being created
      return;
    }

    if (_.isNil(formMode)) {
      formMode = sheet.draft;
    }
    const oldActiveSheet = this.activeSheet;

    if (oldActiveSheet === sheet && this.formMode === formMode) return;
    this.formMode = formMode;
    if (!suppressSync && oldActiveSheet instanceof ReportBuilderDraft) {
      oldActiveSheet.sync();
    }

    if (this.formMode && this.sheetForm) {
      this.sheetForm.importFormData(sheet.getForm());

      // Prevent triggering sheetForm.formData.updateYOY() which fires anytime the globalDateRange
      // changes (is set) **after** the date selector has been initialized
      this.sheetForm.satPickerInit = true;
    }

    if (sheet instanceof ReportBuilderSheet) {
      const id = sheet.activity.getId();
      if (!this.trackedByMixpanel[id]) {
        this.trackedByMixpanel[id] = true;
        this.mixpanelService.track(MixpanelEvent.RESULTS_VIEWED, {
          'Application': this.config.getApplication().display,
          'Application ID': this.config.getApplication().id,
          'Application Version': this.config.version,
          'Activity ID': this.report.getId(),
          'Viewed From': 'Application',
          'Sheet ID': id
        });
      }
    }
    sheet.setActive();
    this.setActiveSheetOptionsConfig(formMode);

    setTimeout(() => {
      // This sets propagates the new sheet's schema throughout both this component & the child form, as well as the QueryModeComponent
      /*
       ICE-4179: For MT Lite, on existing RB result page, user can add new sheets and select different schemas on each new sheet draft.
       When switching between these sheet drafts, the selected schema should be saved and passed to
       getQueryModeDefaultSchema() as overrideSchema.
      */
      const schema = sheet.getForm().schema; // When no schema is selected by user, sheet.getForm().schema is not blank, but primaryEntity.
      this.queryMode.setSchema(UtilsService.getQueryModeDefaultSchema(schema === EnvConfigService.getConfig().primaryEntity? '' : schema));
    });

    const grid = (sheet as ReportBuilderSheet).gridVisualOptions?.apiRef().grid;
    if (!grid) return;
    this.updateGridSize(grid);
  }

  public addBlankDraft() {
    this.addDraft(new ReportBuilderDraft(this), true);
    this.mixpanelService.track(MixpanelEvent.PLATFORM_ACTION, {
      'Application': this.config.getApplication().display,
      'Action': 'Draft Created',
      'Parent Report ID': this.report.getId(),
      'Created From': 'Blank Draft'
    });
  }

  public updateLastSyncedTxt() {
    this.lastSyncedTxt = 'Last synced ' + new RelativePipe().transform(this.model.lastSynced);
  }

  public cloneSheet(sheet: AbstractReportBuilderEntity) {
    const sheetClone = sheet.clone();
    this.addDraft(sheetClone, true);
    this.mixpanelService.track(MixpanelEvent.REPORTS_CLONED, {
      'Application': this.config.getApplication().display,
      'Name': sheet.getForm().name,
      'JSON': sheetClone.toJson(),
      'Parent Report ID': this.report.getId(),
      'Type': 'Sheet'
    });
  }

  // Navigates back to the report list
  public back() {
    this.router.navigate(['app/report-builder']);
  }

  /* new */
  public exportReportExcel() {
    const sheets = this.sheets.filter(s => s instanceof ReportBuilderSheet);

    // Ensure all sheets are ready for export before proceeding
    let userNotified = false;

    combineLatest(
      sheets.map((s: ReportBuilderSheet) => s.readyForExport$)
    )
    .pipe(
      take(1)
    ).subscribe((r: boolean[]) => {
      let canExport = true;
      for (let i = 0; i < r.length; i++) {
        if (!r[i]) {
          canExport = false;
          break;
        }
      }

      if (canExport) {
        this.startDownloading$.next('report');
      } else if (!userNotified) {
        this.notificationService.info('This report will be downloaded once all sheets are ready.', 'Download Pending');
        userNotified = true;
      }
    });
  }

  /* new */
  public exportReportExcelSheet() {
    if ((this.activeSheet as ReportBuilderSheet).readyForExport$.value) {
      this.startDownloading$.next('sheet');
    } else {
      // Ensure this sheet is ready for export before proceeding
      (this.activeSheet as ReportBuilderSheet).readyForExport$
      .pipe(
        take(1)
      )
      .subscribe(r => {
        if (r) {
          this.startDownloading$.next('sheet');
        }
      });
    }

  }

  public drilldown(newDimension: CmsField, dataNode: any) {
    this.reportBuilderService.drilldown(this, newDimension, dataNode)
    .subscribe(drilldownActivity => {
      const _sheets = this.sheets.slice();
      _sheets.push(new ReportBuilderSheet(this, drilldownActivity));
      this.sheets$.next(_sheets);
      this.sync();
    });
  }

  /**
   * Examine if Run All Drafts button should be visible
   * @private
   */
  public isRunAllDraftsVisible(): boolean {
    //“submittable” is at least 1 dimension + 1 metric is selected on a sheet. Sheets with only dimensions or metrics selected cannot be submitted.
    const submittableDrafts = this.sheets.filter(sheet => sheet instanceof ReportBuilderDraft && sheet.data.isRunnable()).length;

    //2+ draft sheets, at least one is “submittable” → the “Run All Drafts”  visible and active on all draft sheets.
    if (this.countDrafts >= 2 && submittableDrafts >= 1 && this.activeSheet.draft) {
      return true;
    }

    return false;
  }

  public runAllDrafts() {
    /**
     * adjusted code block from ReportBuilderResultComponent.formSubmit()
     * adjust: remove activeSheet related logic
     *
     * @param draft, draft sheet we want to add to current report
     */
    const addSheetFromDraft = (draft) => {
      this.sheetForm.importFormData(draft.getForm());
      const formData = this.sheetForm.formData;
      let sheetId: string;
      this.reportBuilderService
      .addSheetToReport(formData, this.report.getId(), 'Add Sheet')
      .pipe(
        mergeMap((sheetJson: any) => {
          sheetId = sheetJson.appActivityId;
          return this.reportBuilderService.initializeSheetMetadata(formData, sheetJson);
        })
      )
      .subscribe(() => {
        draft.convertToSheet(sheetId);
        this.sheetForm.model.running = false;
      });
    };

    const validDrafts = this.sheets.filter(sheet => sheet instanceof ReportBuilderDraft && sheet.data.isRunnable());
    if (validDrafts.length) {
      this.sheetForm.model.running = true;
    }
    validDrafts.forEach(draft => addSheetFromDraft(draft));
  }

  public formSubmit() {
    const formData = this.sheetForm.formData;
    this.sheetForm.model.running = true;
    const sheet = this.activeSheet;

    if (sheet instanceof ReportBuilderSheet) {
      // Edit sheet
      const sheetId = (this.activeSheet as ReportBuilderSheet).activity.getId();
      this.reportBuilderService.editSheet(formData, this.report.getId(), sheetId)
      .pipe(
        mergeMap(sheetJson => this.reportBuilderService.initializeSheetMetadata(formData, sheetJson)),
        delay(1000),
        mergeMap(() => this.reportBuilderService.getSheet(sheetId, this.report.getId()))
      )
      .subscribe(sheetActivity => {
        this.mixpanelService.track(MixpanelEvent.ACTIVITY_EDITED, {
          'Application': this.config.getApplication().display,
          'Activity ID': sheet.activity.getId(),
          'Columns': formData.columns.map(c => c.ref.id),
          'Size': formData.size(),
          'JSON': formData.toJson(),
          'Parent Report ID': this.report.getId(),
          'Type': 'Sheet',
        });
        const updatedSheet = new ReportBuilderSheet(this, sheetActivity);
        updatedSheet.readyForExport$.next(false);

        // Create shallow copy of sheets model and replace the index with new sheet
        const _sheets = this.sheets.slice();
        const sheetIndex = _.findIndex(_sheets, this.activeSheet);
        _sheets[sheetIndex] = updatedSheet;
        this.sheets$.next(_sheets);
        this.sheetForm.model.running = false;
        this.setActiveSheet(updatedSheet, false);
      });

    } else if (sheet instanceof ReportBuilderDraft) {
      // Add sheet
      let sheetId: string;
      this.reportBuilderService.addSheetToReport(formData, this.report.getId(), 'Add Sheet')
      .pipe(
        mergeMap((sheetJson: any) => {
          sheetId = sheetJson.appActivityId;
          return this.reportBuilderService.initializeSheetMetadata(formData, sheetJson);
        })
      )
      .subscribe(() => {
        sheet.convertToSheet(sheetId);
        this.sheetForm.model.running = false;
      });
    }
  }

  public getAsyncTotals(): void {

    // this.setTotalsLoading(true);
    // const sheet = this.activeSheet as ReportBuilderSheet;
    // const createParams = new Subject<void>(); // Observable for tracking when params are ready to be created
    //
    // const factColGroups: ResultColumnGroup[] = _.filter(sheet.data.colGroups, colGroup => !isNaN(Number(colGroup.id)));
    // const globalFilters: SiqFilter[] = [];
    // const formData = this.reportBuilderService.createForm(sheet.activity.getFormValues());

    // if (!_.isEmpty(formData.globalFilters.value)) {
    //   globalFilters.push(
    //     ..._.map(formData.globalFilters.value, (formControl) => {
    //       return SiqFilter.fromJson(formControl);
    //     })
    //   );
    // }

    // createParams.subscribe(() => {
    //   // Logic below runs when it is deemed "ready" via createParams observable
    //
    //   let tempActivity: Activity = new Activity();
    //
    //   // Create a job for each of the async metric/facts
    //   const asyncMetrics = _.filter(sheet.data.metrics, {totallingType: AggregationType.ASYNC});
    //   if (asyncMetrics) {
    //     asyncMetrics.forEach((aM, idx) => {
    //       const job = new AppResponseDataset();
    //       job.setName(idx.toString());
    //
    //       let tempSr = _.cloneDeep(sheet.sr);
    //       let relevantFact = _.find(tempSr.metrics, {id: aM.id});
    //       tempSr.setFacts([relevantFact]);
    //
    //       job.setResponse(tempSr);
    //
    //       const viewsParameters = new ViewsParameters();
    //       viewsParameters.metrics.push(aM.id);
    //       job.setParams(viewsParameters);
    //
    //       tempActivity.getJobs().push(job);
    //     });
    //   }
    //
    //   const asyncTotalSheetId = _.cloneDeep(sheet.activity.id);
    //   GridTotalsService.getTotalsExpandedApps(
    //     {
    //       activity: tempActivity,
    //       apiRef: sheet.apiRef,
    //       colDefMeta: sheet.data.colDefMeta,
    //       factColGroups: factColGroups,
    //       globalFilters: globalFilters,
    //       rbResultData: sheet.data
    //     } as AsyncTotalsParameters
    //   ).pipe(
    //     finalize(() => {
    //       this.resetTotalsButton(asyncTotalSheetId);
    //     }),
    //     takeUntil(this.unsubTotals$ || sheet.data.unsubTotals)
    //   )
    //   .subscribe(
    //     /*
    //      * This left intentionally blank, but must be present in order for the finalize() function to fire.
    //      * The finalize() function will fire after both success and failure.
    //      */
    //   );
    // });
    //
    // if (formData.lwh) {
    //   // Need to run LWH query and use as store filters
    //   this.lwhService.runQuery(formData.lwh.months, formData.lwh.date, 'STORE_ID')
    //     .subscribe(stores => {
    //       _.remove(globalFilters, f => f.getName() === 'LOC_STORE');
    //       globalFilters.push(new SiqFilter('LOC_STORE', stores, [], false, false));
    //       createParams.next();
    //       createParams.complete();
    //     });
    // } else {
    //   // Immediately start params creation if LWH is not applied
    //   createParams.next(null);
    //   createParams.complete();
    // }
  }

  gridFeatureUsed(feature: string) {
    this.mixpanelService.track(MixpanelEvent.FEATURE_SELECTED, {
      'Application': this.config.getApplication().display,
      'Feature': feature
    });
  }

  handleOptionMenuClick(clickedItem: string) {
    switch (clickedItem) {
      case ActivityGridOptionsMenu.AUTOSIZE_COLUMNS.trim():
        this.autoSize();
        break;
      case this.TOOL_PANEL.trim():
        this.toggleToolPanel();
        break;
      case this.DOWNLOAD_CSV.trim():
        this.startDownloading$.next('csv');
        break;
      case this.DOWNLOAD_EXCEL_SHEET.trim():
        this.exportReportExcelSheet();
        break;
      case this.DOWNLOAD_EXCEL_REPORT.trim():
        this.exportReportExcel();
        break;
      case ActivityGridOptionsMenu.SHARE_SCHEDULE.trim():
        this.share();
        break;
      case this.CLONE_REPORT.trim():
        this.clone();
        break;
      default:
        break;
    }
  }

  // Shows/hides the tool panel
  public toggleToolPanel() {
    const grid = (this.activeSheet as ReportBuilderSheet).gridVisualOptions.apiRef().grid;
    if (!grid) return;
    grid.api.setSideBarVisible(!grid.api.isSideBarVisible());
  }

  public autoSize() {
    const grid = (this.activeSheet as ReportBuilderSheet).gridVisualOptions.apiRef().grid;
    grid.api.sizeColumnsToFit();
    setTimeout(() => grid.api.autoSizeAllColumns(), 150);
  }

  public deleteSheet(sheet: AbstractReportBuilderEntity) {
    sheet.delete();
    if (sheet instanceof ReportBuilderSheet) { // do not track draft deletion
      this.mixpanelService.track(
        MixpanelEvent.ACTIVITY_DELETED,
        {
          'Action': 'Sheet Deleted',
          'Parent Report ID': this.report.getId(),
          'Parent Report Name': this.report.getName(),
          'Parent Report Created By': this.report.getCreatedBy(),
          'Type': 'Sheet',
        }
      );
    }
  }

  public setActiveSheetOptionsConfig(formMode: boolean): void {
    if (_.isNil(this.activeSheet)) return;

    if (formMode && this.report.accessGroupChanged && !this.activeSheet.draft) {
      this.activeSheetOptionsConfig = this.cloneOnlyMenuItems;
    }

    if (!formMode && !this.activeSheet.draft) {
      if (this.report.accessGroupChanged && !this.activeSheet.draft) {
        this.activeSheetOptionsConfig = this.cloneOnlyMenuItems;
      }

      if (this.activeSheet?.status === this.statusEnums.ALERT) {
        this.activeSheetOptionsConfig = this.cloneOnlyMenuItems;
      }

      if ((<ReportBuilderSheet>this.activeSheet)?.ready &&
        (this.activeSheet.status !== this.statusEnums.ALERT && this.activeSheet.status !== this.statusEnums.ERROR)) {
        this.activeSheetOptionsConfig = this.customOptionMenuItems;
      }
    }
  }

  // Disable the button and prohibit user from modifying RowGroups or Values in ToolPanel while totals are loading
  public setTotalsLoading(isLoading: boolean) {
    (this.activeSheet.data as ReportBuilderResultData).asyncTotalsLoading.next(isLoading);
  }

  // Sends a sync event to the debouncer
  public sync() {
    this.syncDebouncer$.next(true);
  }

  public updateGridSize(grid: AgGridAngular): void {
    this.model.numRows = grid.api.getDisplayedRowCount();
    this.model.numCols = grid.api.getAllDisplayedColumns().length;
  }

  private init(report: ReportActivity) {
    this.report = report;
    this.reportName = report.getName();

    this.sheets$
    .pipe(takeUntil(this.unsub$))
    .subscribe(sheets => {
      const numSheets = sheets.filter(s => !s.draft).length;
      this.model.canDelete = numSheets > 1;
      this.sheets = sheets.slice(); // Set internal model to a shallow copy of the array passed in
      this.countDrafts = sheets.length - numSheets;

      this.ready();
    });

    // cloud export hook
    this.sheets$
    .pipe(
      take(1),
      map(sheets => sheets.filter(s => s instanceof ReportBuilderSheet))
    )
    .subscribe((sheets: ReportBuilderSheet[]) => {
      this.attachCloudData(sheets);
    });

    // Ownership check
    this.readonly = !this.report.isMine();

    this.activityService.markViewed(this.report, this.route.snapshot.queryParamMap.get('source'));

    if (!this.report.isMine()) { // user is not report owner, cannot share/schedule report
      this.customOptionMenuItems = [
        ActivityGridOptionsMenu.AUTOSIZE_COLUMNS,
        this.TOOL_PANEL,
        this.DOWNLOAD_CSV,
        this.DOWNLOAD_EXCEL_SHEET,
        this.DOWNLOAD_EXCEL_REPORT,
        this.CLONE_REPORT
      ];
      this.activeSheetOptionsConfig = this.customOptionMenuItems;
    }
  }

  private loadState(): boolean | void {
    // Attempt to parse out the report's state
    let state: any[];

    try {
      state = JSON.parse(this.report.getMetaDataByKey('state'));
    } catch {
      // If parsing state fails (or doesn't exist)...
      // First check if user is attempting to load a sheet directly instead of a top-level report
      if (this.report.isReportActuallySheet()) {
        return true;
      } else {
        // Use default configuration
        return this.loadWithoutState();
      }
    }

    const _sheets = [];
    state.forEach(stateObj => {
      if (typeof stateObj === 'string') {
        // Either a sheet ID
        const sheetActivity = this.report.sheets.find(a => a.getId() === stateObj);
        _sheets.push(new ReportBuilderSheet(this, sheetActivity));
      } else {
        // Or a sheet draft
        if (this.readonly) return;
        const formData = this.reportBuilderService.createForm(stateObj);
        _sheets.push(new ReportBuilderDraft(this, formData));
      }
    });

    this.sheets$.next(_sheets);
  }

  private loadWithoutState() {
    const _sheets = this.report.sheets.map(sheetActivity => new ReportBuilderSheet(this, sheetActivity));
    this.sheets$.next(_sheets);
  }

  // Parse out the 's' query param (if it exists - and jump to sheet)
  private parseQueryParams() {
    this.route.queryParams.subscribe(qp => {
      const id = qp.s;
      let sheet;
      if (id) {
        sheet = _.find(this.sheets, s => {
          if (!(s instanceof ReportBuilderSheet)) return false;
          return s.activity.getId() === id.toString();
        });
      }
      if (sheet) {
        this.setActiveSheet(sheet);
      } else {
        if (this.sheets.length) {
          setTimeout(() => this.setActiveSheet(this.sheets[0]));
        }
      }
    });
  }

  // This should only be called by the syncDebouncer. Any event attempting to start a sync event should use sync()
  private syncReport() {
    if (this.readonly) return;

    this.model.syncing = true;
    const json = {} as any;
    json.name = this.reportName;
    json.state = JSON.stringify(this.sheets.map(s => s.toJson()));
    json.sheets = this.sheets
    .filter(s => s instanceof ReportBuilderSheet)
    .map(s => (s as ReportBuilderSheet).activity.getId());
    this.reportBuilderService.updateEntityMeta(this.report, json)
    .subscribe(() => {
      this.model.syncing = false;
      this.model.lastSynced = new Date();
      this.updateLastSyncedTxt();
    });
  }

  private addDraft(sheet: AbstractReportBuilderEntity, jumpTo?: boolean) {
    const _sheets = this.sheets.slice();
    _sheets.push(sheet);
    this.sheets$.next(_sheets);
    this.sync();
    jumpTo && this.setActiveSheet(sheet);
  }

  private resetTotalsButton(sheetActivityId: string): void {
    const s = _.find(this.sheets, {activity: {id: sheetActivityId}});
    if (s) {
      (s.data as ReportBuilderResultData).asyncTotalsLoading.next(false);
    }

    (s.data as ReportBuilderResultData).unsubTotals.next();
  }

  public share() {
    this.sharingService.share(this.report);
  }

  public clone() {
    this.reportBuilderService.cloneReport(this.report.getId());
  }
}
