import { Injectable } from '@angular/core';
import { SiqHttpService } from 'app/core/services/siq-http/siq-http.service';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Activity } from 'app/activity/models/activity.model';
import { ActivityResultType, ActivityService } from 'app/activity/services/activity.service';
import { ApplicationHash, WeekEndingDay } from '@siq-js/core-lib';
import {
  NotificationService,
  NotificationType,
  ResponseCode,
  ResponseCodes,
  ResponseCodesConfig
} from '@siq-js/angular-buildable-lib';
import {
  DaypartsFormData,
  DaypartsFormJson
} from 'app/siq-applications/modules/dayparts/models/form/dayparts-form-data.model';
import { FilterSelection } from 'app/filter/models/filter-selection';
import { AppRequest } from 'app/siq-applications/modules/shared/models/app-request.model';
import { map, tap } from 'rxjs';
import { MixpanelService } from 'app/core/services/mixpanel/mixpanel.service';
import { MixpanelEvent } from 'app/core/services/mixpanel/mixpanel-event.enum';
import { DaypartsParameters } from 'app/siq-applications/modules/dayparts/models/form/dayparts-parameters.model';
import { AppDataset } from 'app/siq-applications/modules/shared/models/app-dataset.model';
import { DaypartsConfig } from 'app/siq-applications/modules/dayparts/models/dayparts-config.model';
import * as _ from 'lodash';
import { DaypartsDatasetType } from 'app/siq-applications/modules/dayparts/models/form/dayparts-dataset-type.enum';
import { Router } from '@angular/router';
import { DaypartsActivity } from 'app/siq-applications/modules/dayparts/models/dayparts-activity.model';
import { ExcelService, VisualOptions } from '@siq-js/visual-lib';
import { DatesService } from 'app/siq-forms/modules/dates/services/dates.service';
import { DateRangeInterfaceType } from 'app/siq-forms/modules/dates/models/interfaces';

@Injectable()

export class DaypartsService extends SiqHttpService {
  public static Activities$: BehaviorSubject<DaypartsActivity[]>;
  public static readonly apiPath = 'app/dayparts';

  public clonedActivity: Activity;

  private overrideCodes: ResponseCode[] = [];

  constructor(
    private activityService: ActivityService,
    protected http: HttpClient,
    protected notificationService: NotificationService,
    private config: DaypartsConfig,
    private mixpanelService: MixpanelService,
    private router: Router
  ) {
    super(http, notificationService);
    if (!DaypartsService.Activities$) {
      DaypartsService.Activities$ = ActivityService.createStream<DaypartsActivity>(
        activities => activities.filter(a => a.getAppId() === ApplicationHash.DAYPARTS && (!a.isMine() || (a.isMine() && !a.isSharedOrScheduled()))).map(a => a as DaypartsActivity)
      );
    }
  }

  // Clone an affinities form using formValues from an activity
  public cloneReport(id: string) {
    this.getReport(id, ActivityResultType.NO_RESULTS)
      .subscribe(activity => {
        this.clonedActivity = activity;
        this.router.navigate(['/app/dayparts/~']);
      });
  }

  /**
   * Create DaypartsFormData
   * Optional field json can be passed in - allowing it to be repopulated from the formValues field of the report
   * If no json passed in, create a blank formData
   * @param fv: DaypartsFormJson used to create DaypartsFormData
   */
  public createForm(fv?: DaypartsFormJson): DaypartsFormData {
    const formData = new DaypartsFormData();

    if (fv) {
      formData.name = fv.name;
      formData.schema = fv.schema;
      formData.isCloned = fv.isCloned;
      formData.dateRanges = DatesService.isDynamic(fv.dateRanges)
        ?
        {
          end: new Date(DatesService.dateStoMS(fv.dateRanges.end)),
          begin: new Date(DatesService.dateStoMS(fv.dateRanges.begin)),
          dynamicBegin: fv.dateRanges.dynamicBegin,
          dynamicEnd: fv.dateRanges.dynamicEnd,
        }
        :
        {
          end: new Date(DatesService.dateStoMS(fv.dateRanges.end)),
          begin: new Date(DatesService.dateStoMS(fv.dateRanges.begin))
        };
      formData.dateRanges.type = DateRangeInterfaceType.POPULATED;
      formData.filters = fv.filters.map(f => new FilterSelection(f));
      if (fv.weekEndingDay) {
        formData.weekEndingday = fv.weekEndingDay;
      } else { // If there's no weekEndingDay, means it's an old report created before WE project.
        formData.weekEndingday = WeekEndingDay.OLD_REPORT; // According to ICE-2295: Old reports before WE project used Saturday as default WE. RB TimeBrekDown used Sunday as default.
      }
    }

    return formData;
  }

  /**
   * Sends the POST request to create a report
   */
  public createReport(formData: DaypartsFormData): Observable<any> {
    this.overrideCodesForCreateRequests(formData);

    // if name is not set(undefined), use default value
    if (_.isNil(formData.name)) {
      formData.name = Activity.ActivityPlaceholder;
    }

    let metaData = new Map<string, string>();
    metaData.set('analysisType', formData.getAnalysisType());

    let appRequest = new AppRequest(
      [
        new AppDataset('HOUR', new DaypartsParameters(formData, DaypartsDatasetType.HOUR)),
        new AppDataset('DAYOFWEEK', new DaypartsParameters(formData, DaypartsDatasetType.DAYOFWEEK)),
        new AppDataset('HEATMAP', new DaypartsParameters(formData, DaypartsDatasetType.HEATMAP))
      ],
      formData.toJson(),
      metaData
    );

    appRequest.setMetaDataEntry('name', formData.name?.trim());

    return this.create({ endpoint: DaypartsService.apiPath, body: appRequest.asJsonObject() })
      .pipe(
        map(res => res.body),
        tap(res => {
          ActivityService.refresh();
          this.mixpanelService.track(MixpanelEvent.ACTIVITY_CREATED, {
            'Application': this.config.getApplication().display,
            'Activity ID': res['appActivityId'],
            'JSON': formData.toJson(),
            'Name': formData.name,
            'Type': 'Report'
          });
        })
      );
  }

  private overrideCodesForCreateRequests(formData: DaypartsFormData) {
    if (formData.isCloned) {
      this.overrideResponseCode(
        200,
        NotificationType.SUCCESS,
        'Report Cloned',
        'Your report has been cloned.'
      );
    } else {
      this.overrideResponseCode(
        200,
        NotificationType.SUCCESS,
        'Report Created',
        'Your report has been created and is now running.'
      );
    }
  }

  // Get an activity using id
  public getReport(reportId: string, resultType: ActivityResultType = ActivityResultType.WITH_RESULTS): Observable<DaypartsActivity> {
    return this.activityService.getActivity<DaypartsActivity>({
      id: reportId,
      resultType: resultType
    });
  }

  public getResponseCodes(responseCodesConfig: ResponseCodesConfig): ResponseCodes {
    return new ResponseCodes(this.overrideCodes);
  }

  public overrideResponseCode(code: number, type: NotificationType, header: string, message: string): void {
    _.remove(this.overrideCodes, ['code', code]);
    this.overrideCodes.push(
      new ResponseCode(
        code,
        '<hr/>' + message,
        header,
        type
      )
    );
  }

  public exportSheetAsCSV(reportActivity: Activity, gridVisualOptions: VisualOptions) {
    ExcelService.exportSheetAsCSV(reportActivity.getName(), gridVisualOptions);

    this.mixpanelService.track(MixpanelEvent.REPORTS_EXPORTED, {
      'Application': this.config.getApplication().display,
      'Report ID': reportActivity.getId(),
      'Report Name': reportActivity.getName(),
      'File Type': 'csv',
      'Export Type': 'report'
    });
  }

  public exportSheetAsExcel(reportActivity: Activity, gridVisualOptions: VisualOptions) {
    ExcelService.saveSheetAsXLSX(reportActivity.getName(), gridVisualOptions);

    this.mixpanelService.track(MixpanelEvent.REPORTS_EXPORTED, {
      'Application': this.config.getApplication().display,
      'Report ID': reportActivity.getId(),
      'Report Name': reportActivity.getName(),
      'File Type': 'xlsx',
      'Export Type': 'report'
    });
  }

}
