import { CmsField, CmsMetric } from '@siq-js/cms-lib';
import { AppSiqConstants } from 'app/core/models/constants/app-constants';
import { FormColumn } from 'app/siq-applications/modules/report-builder/models/form/form-column.model';
import { GridService, ProcessHeaderForExportParams, RowClickedEvent } from '@siq-js/visual-lib';
import { map, mergeMap, tap } from 'rxjs';
import { ReportBuilderResultData } from 'app/siq-applications/modules/report-builder/models/results/report-builder-result-data.model';
import * as _ from 'lodash';
import { Activity, ActivityStatus } from 'app/activity/models/activity.model';
import { ActivityResultType, ActivityService } from 'app/activity/services/activity.service';
import { AppResponseDataset } from 'app/siq-applications/modules/shared/models/app-response-dataset.model';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { DimensionColumn } from 'app/siq-applications/modules/report-builder/models/form/dimension-column.model';
import { UntypedFormBuilder } from '@angular/forms';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MetricColumn } from 'app/siq-applications/modules/report-builder/models/form/metric-column.model';
import { ReportActivity } from 'app/siq-applications/modules/report-builder/models/report-activity.model';
import { ReportBuilderConfig } from 'app/siq-applications/modules/report-builder/models/report-builder-config';
import {
  ReportBuilderFormData,
  ReportBuilderFormJson
} from 'app/siq-applications/modules/report-builder/models/form/report-builder-form-data.model';
import { ReportBuilderParameters } from 'app/siq-applications/modules/report-builder/models/form/report-builder-parameters.model';
import { ReportBuilderSheet } from 'app/siq-applications/modules/report-builder/models/results/report-builder-sheet.model';
import { Router } from '@angular/router';
import { SiqHttpService } from 'app/core/services/siq-http/siq-http.service';
import { UtilsService } from 'app/core/services/utils/utils.service';
import { ReportBuilderResultComponent } from 'app/siq-applications/modules/report-builder/components/report-builder-result/report-builder-result.component';
import { MixpanelEvent } from 'app/core/services/mixpanel/mixpanel-event.enum';
import { MixpanelService } from 'app/core/services/mixpanel/mixpanel.service';
import {
  ApplicationHash,
  ContentType
} from '@siq-js/core-lib';
import {
  ResponseCodesConfig,
  NotificationType,
  ResponseCode,
  ResponseCodes,
  NotificationService
} from '@siq-js/angular-buildable-lib';
import { RBMetricColumn } from 'app/siq-applications/modules/report-builder/models/form/enums';
import { DatesService } from 'app/siq-forms/modules/dates/services/dates.service';
import { FilterSelection } from 'app/filter/models/filter-selection';
import { ActivityFactory } from 'app/activity/models/activity.factory';
import { ExcelService, ExcelExportMultipleSheetParams, GridComponent, ResultColumn, ResultColumnGroup, TextColorType } from '@siq-js/visual-lib';
import { DateRangeInterface } from 'app/siq-forms/modules/dates/models/interfaces';
import { utcToZonedTime } from 'date-fns-tz';
import { getDate, getMonth, isLeapYear } from 'date-fns';
import { SameStoreSalesService } from 'app/siq-forms/modules/same-store-sales/services/same-store-sales.service';

@Injectable()
export class ReportBuilderService extends SiqHttpService {

  public static Activities$: BehaviorSubject<ReportActivity[]>;
  public static readonly apiPath = 'app/reportbuilder';
  private overrideCodes: ResponseCode[] = [];

  public static isDimYOYLocked(field: CmsField): boolean {
    if (['year', 'yearmon', 'quarter'].includes(field.field)) {
      return true;
    }
    if (field.field.includes('fiscal_year')) {
      return true;
    }
    return false;
  }

  public static getCellClassRules(entity: CmsMetric | CmsField, colId?: string): any {
    const key = GridService.getDataKey(entity);
    const out = {};
    const _determineValue = (_context): number => {
      let v;
      if (_context.data && _context.data[_context.colDef.colId]) {
        v = _context.data[_context.colDef.colId];
      }

      // If "v" is null or is an object that has a .val property that equals null, then look for _context.value
      const check = _.isObject(v) ? v[key] : v;
      if (_.isNil(check)) {
        v = _.isObject(_context.value) ? _context.value[key] : _context.value;
      }
      let val = _.isObject(v) ? v[key] : v;

      if (typeof val === 'string') {
        val = UtilsService.scrubFormattedVal(val);
      }
      return val;
    };

    if (entity instanceof CmsMetric) {
      _.merge(out, GridService.getDefaultFactCellClassRules());
    }

    switch (entity.id) {
      case 'RB_PERCENT_DELTA':
      case 'RB_DELTA':
        out[TextColorType.SUCCESS] = (context) => {
          const val = _determineValue(context);
          return !_.isNaN(val) && val > 0;
        };
        out[TextColorType.WARN] = (context) => {
          const val = _determineValue(context);
          return !_.isNaN(val) && val < 0;
        };
        break;
    }

    if (entity.id === 'RB_DELTA') {
      out[ExcelService.EXCEL_FORMAT_OVERRIDE_PREPEND + entity.type] = () => true;
    }

    out[GridService.ENUM_ID_PREPEND + entity.id] = () => true;

    return out;

  }

  public static transformYOYDimVal(dV: string, dim: CmsField, offset: number): string {
    if (_.isNil(dim)) return dV;

    // The offset needs to be modified if a Week Ending X dimension is present
    // (offsets must be multiples of 7 to match TY and LY dimension values)
    if (dim.field.includes('week_end')) {
      offset = Math.round(offset / 7) * 7;
    }

    if (['year', 'yearmon', 'quarter', 'fiscal_year'].includes(dim.field)) {
      let date = new Date(Number(dV));
      // Get the current year in UTC
      let currentYear = date.getUTCFullYear();
      // Add one year
      date.setUTCFullYear(currentYear + 1);
      return date.getTime().toString();
    }

    if (dim.type === 'DATE_FISCAL') {
      const fiscalDate = dV.split('-');
      fiscalDate[0] = (Number(fiscalDate[0]) + 1).toString();
      return fiscalDate.join('-');
    }

    if (dim.filter === 'DATE') {
      let date = new Date(Number(dV));
      // LY is leap year and the date is 2/29. So no matching TY date, just return
      if (isLeapYear(Number(dV)) && date.getUTCMonth() === 1 && date.getUTCDate() === 29) {
        return dV;
      }
      if (dim.field.includes('week_end')) {
        date = DatesService.add(Number(dV), { days: offset });
      } else {
        // Get the current year in UTC
        let currentYear = date.getUTCFullYear();
        // Add one year
        date.setUTCFullYear(currentYear + 1);
      }
      // Need to to account for discrepancies in the timestamp due to daylight savings. Set the UTC time to 0am, ie start of the day to solve this.
      date.setUTCHours(0, 0, 0, 0);
      return date.getTime().toString();
    }

    return dV;
  }

  public static isTimeAgg(j: AppResponseDataset | ResultColumnGroup): boolean {
    if (j instanceof AppResponseDataset) {
      return j.getName() === RBMetricColumn.TIME_AGGREGATE;
    } else {
      return j.flag === RBMetricColumn.TIME_AGGREGATE;
    }
  }

  public static isYOY(j: AppResponseDataset | ResultColumnGroup): boolean {
    if (j instanceof AppResponseDataset) {
      return j.getName().includes(RBMetricColumn.YEAR_OVER_YEAR);
    } else {
      return j.flag && j.flag.includes(RBMetricColumn.YEAR_OVER_YEAR);
    }
  }

  public static isNormalMetric(j: AppResponseDataset): boolean {
    return j.getName() === RBMetricColumn.NORMAL_METRIC;
  }

  // This processes a time aggregate app activity job (using new valuesMatrix and dimensionMatrix)
  public static processTimeAggJob(dataMap: any, job: AppResponseDataset, colGroupId: string, resultData: ReportBuilderResultData, jobIdTimeAggValToColIdxMap: Map<string, number>) {
    const sr = job.getResponse();
    resultData.dims = sr.getDimensions(true).slice(0, -1); // includeNulls so GrandTotal calcs that use null/NO_DIM are included
    const vM = sr.getValues();
    const dM = sr.getDimensionValues();
    const wM = sr.getWeights() || [];
    const fact = sr.facts[0];

    /**
     * Previously we would add a column for each time aggregate dimension value (in a loop).
     * Now with possible paging (multiple requests to get data from backend), we need to use jobIdTimeAggValToColIdxMap
     * to make sure no duplicate col is created
     */
    const timeAggDimType = GridService.getVisualType(sr.getDimensions()[sr.getDimensions().length - 1].type);
    if (!_.isEmpty(dM)) {
      dM[dM.length - 1].forEach(timeAggVal => {
        const jobId = job.getResponse().jobId;
        const key = `${jobId}_${timeAggVal}`;

        if (!jobIdTimeAggValToColIdxMap.has(key)) {
          let meta = {
            val: timeAggDimType.parse(timeAggVal),
            type: timeAggDimType,
            isTimeAgg: true,
            parentJob: job // used in async totals requests
          };
          jobIdTimeAggValToColIdxMap.set(key, resultData.getCurrValIndex()); // store the col index
          resultData.addColumn(colGroupId, fact, meta, job);
        }
      });
    } else {
      // dimensionMatrix is empty (no data); therefore we do not know what time-breakdown columns to add
      const meta = {
        val: 'NO-TIME-BREAKDOWN-DATA-AVAILABLE',
        type: GridService.getVisualType('string'),
        isTimeAgg: true,
        parentJob: job
      };
      resultData.addColumn(colGroupId, fact, meta, job);
    }

    let pointer: any;

    // All Time-Aggregates jobs will have one extra dimension (the time-series segment dimension)
    // and those need to be separated into separate pseudo-columns/fields. The algorithm below accounts for
    // all each dimension value of that last dimension, and flattens them into individual key-value pairs in the data object

    /**
     * Fix for Multi-pages Time Breakdown
     * Previous code problem: 
     * For example, in one page response, the TB dim values are ['aaaaaaa', 'bbbbbbb'] and the corresponding values are [x, y], previous code will generate the result as [aaaaaaa -> x, bbbbbbb -> y]. 
     * In another page response, the TB dim values are ['ccccccc', 'aaaaaaa'] and the corresponding values are [m, n] previous code will generate the result as [ccccccc -> m, aaaaaaa -> n].
     * You can see that for TB column 0 (first element in the result), we have values corresponding to two TB values aaaaaaa and ccccccc.
     * Fix: 
     * By using the jobIdTimeAggValToColIdxMap, to get the TB col index. 
     * The final result for first page response will be [aaaaaaa -> x, bbbbbbb -> y] and for second page response will be [aaaaaaa -> n, NULL_POINT, ccccccc -> m].
     */

    vM.forEach((valArr) => {
      pointer = dataMap;
      valArr.forEach((v, i) => {
        if (resultData.dims[i]) {
          const dimValue = dM[i][v];
          pointer[dimValue] = pointer[dimValue] || ((i < dM.length - 2) ? {} : []);
          pointer = pointer[dimValue];
        } else if (i === valArr.length - 1) {
          const offsetIndex = Number(valArr[i - 1]);
          const colIdx = jobIdTimeAggValToColIdxMap.get(`${job.getResponse().jobId}_${dM[dM.length - 1][offsetIndex]}`);
          while (colIdx > pointer.length-1) {
            pointer.push(GridService.NULL_POINT);
          }
          pointer[colIdx] = { val: Number(v) };
        }
      });
    });

    wM.forEach((valArr) => {
      pointer = dataMap;
      valArr.forEach((v, i) => {
        if (resultData.dims[i]) {
          const dimValue = dM[i][v];
          pointer[dimValue] = pointer[dimValue] || ((i < dM.length - 2) ? {} : []);
          pointer = pointer[dimValue];
        } else if (i === valArr.length - 1) {
          const offsetIndex = Number(valArr[i - 1]);
          const colIdx = jobIdTimeAggValToColIdxMap.get(`${job.getResponse().jobId}_${dM[dM.length - 1][offsetIndex]}`);
          while (colIdx > pointer.length-1) {
            pointer.push(GridService.NULL_POINT);
          }
          pointer[colIdx]['weight'] = Number(v);
        }
      });
    });
  }

  // This processes a year-over-year job, although all of the dataMap manipulation propagates from the processMetricJob() call at the end
  public static processYearOverYearJob(dataMap: any, job: AppResponseDataset, colGroupId: string, resultData: ReportBuilderResultData) {
    const sr = job.getResponse();
    const dims = sr.getDimensions(true); // includeNulls so GrandTotal calcs that use null/NO_DIM are included
    const dM = sr.getDimensionValues();

    // All but the last line does some pre-processing to the dimensionsMatrix (dimensionValues)
    // To make them suitable for merging for TY vs LY calculations

    let offset = Number(job.getName().split('|').reduce((p, c) => {
      // Parse out the offset # of days
      if (c.includes('offset')) {
        return c.split(':')[1];
      }
    }));
    // The offset needs to be modified if a Week Ending X dimension is present (offsets must be multiples of 7 to match
    // TY and LY dimension values)
    const weekDimIndex = _.findIndex(dims, d => d.field.includes('week_end_'));
    if (~weekDimIndex) {
      offset = Math.round(offset / 7) * 7;
    }
    dM.forEach((dArr, i) => {
      dM[i] = dArr.map((dV) => ReportBuilderService.transformYOYDimVal(dV, dims[i], offset));
    });

    // This does the actual dataMap manipulation (with the newly processed dimensionsMatrix)
    ReportBuilderService.processMetricJob(dataMap, job, colGroupId, resultData);
  }

  // This processes both normal metrics and year-over-year jobs (using new valuesMatrix and dimensionMatrix)
  public static processMetricJob(dataMap: any, job: AppResponseDataset, colGroupId?: string, resultData?: ReportBuilderResultData): any {
    const sr = job.getResponse();
    const fact = sr.facts[0];
    const vM = sr.getValues();
    const dM = sr.getDimensionValues();
    const wM = sr.getWeights() || [];
    let pointer: any;
    let col: ResultColumn;

    if (resultData) {
      resultData.dims = sr.getDimensions();

      let meta;
      if (this.isYOY(job)) {
        meta = {
          parentJob: job
        }; // used in async totals requests
      }

      // When there are multipe pages returned from BE, use jobId to identify columns, assure same column is only added once.
      const colGroup = resultData.getColgroupById(colGroupId);
      const match = _.find(colGroup.children, {job: {response: {jobId: job.getResponse().jobId}}});
      col = match ? match : resultData.addColumn(colGroupId, fact, meta, job);
    }

    // Since delta & percent delta columns are calculated dynamically, both "This Year" and "Last Year" columns can be
    // processed normally as a metric column
    vM.forEach((valArr) => {
      pointer = dataMap;
      valArr.forEach((v, i) => {
        if (dM[i]) {
          const dimValue = dM[i][v];
          pointer[dimValue] = pointer[dimValue] || ((i < dM.length - 1) ? {} : []);
          pointer = pointer[dimValue];
        } else {
          const valIndex = resultData ? Number(col.valKey) : 0;
          if (pointer.length !== valIndex) {
            for (let j = pointer.length; j < valIndex; j++) {
              pointer.push(GridService.NULL_POINT);
            }
          }
          pointer.push({
            val: Number(v),
          });
        }
      });
    });

    // Process weights matrix (if present)
    wM.forEach((valArr => {
      pointer = dataMap;
      valArr.forEach((v, i) => {
        if (dM[i]) {
          const dimValue = dM[i][v];
          pointer[dimValue] = pointer[dimValue] || ((i < dM.length - 1) ? {} : []);
          pointer = pointer[dimValue];
        } else {
          const valIndex = resultData ? Number(col.valKey) : 0;
          pointer[valIndex]['weight'] = Number(v);
        }
      });
    }));
  }

  // The datamap used to generate a report must have inner arrays of congruous length - This function fills shorter arrays with nulls
  public static normalizeDataMap(dataMap: any, desiredLength: number) {
    if (Array.isArray(dataMap)) {
      for (let i = dataMap.length; i < desiredLength; i++) {
        dataMap.push(GridService.NULL_POINT);
      }
    } else {
      for (let k in dataMap) {
        this.normalizeDataMap(dataMap[k], desiredLength);
      }
    }
  }

  // Function that determines whether a configuration of Report Builder can be further drilled
  // Also accepts a second optional params as a RowCLickedEvent allowing it to hook into click events
  // and act as a second layer of validation
  public static canDrill(table: GridComponent, event?: RowClickedEvent): boolean {
    if (event) {
      if (_.get(event, 'node.data.suppressRowClick')) return false;
      if (!event.data) return false;
    }

    const colDefMeta = table.colDefMeta;
    const grid = table.grid;

    const invalidDims = grid.api.getAllDisplayedColumns()
      .map(c => colDefMeta.get(c.getColDef().colId))
      .filter(meta => {
        if (meta && meta.ref instanceof CmsField) {
          return !meta.ref.filter;
        }
        return false;
      });

    return _.isEmpty(invalidDims);
  }

  constructor(
    protected http: HttpClient,
    protected notificationService: NotificationService,
    private config: ReportBuilderConfig,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private activityService: ActivityService,
    private mixpanelService: MixpanelService,
    private datesService: DatesService
  ) {

    super(http, notificationService);

    if (!ReportBuilderService.Activities$) {
      ReportBuilderService.Activities$ = ActivityService.createStream<ReportActivity>(
        activities => activities.filter(a => a.getAppId() === ApplicationHash.REPORT_BUILDER && (!a.isMine() || (a.isMine() && !a.isSharedOrScheduled()))).map(a => a as ReportActivity)
      );
    }

    ReportBuilderService.Activities$
      .subscribe(reports => {
        const runningSheets = ReportBuilderService.Activities$.getValue().reduce((arr: Activity[], report: ReportActivity) => {
          return arr.concat((report.sheets).filter(s => s.getStatus() === ActivityStatus.RUNNING));
        }, []);

        this.notifyCompletedSheets(runningSheets, reports);
      });
  }

  getResponseCodes(responseCodesConfig: ResponseCodesConfig): ResponseCodes {
    return new ResponseCodes(this.overrideCodes);
  }

  // Creates a new ReportBuilderFormData structure, for use by the ReportBuilderFormComponent.
  // Optional field json can be passed in - allowing it to be repopulated from the formValues field of the report
  // If no json is passed in, creates a blank report form
  public createForm(fv?: ReportBuilderFormJson): ReportBuilderFormData {
    return new ReportBuilderFormData(fv);
  }

  public async cloneReport(id: string) {
    this.getReport(id)
      .subscribe(report => {
        const newReportName = `${report.getName()} (copy)`;
        // step1: create empty report container
        this.overrideResponseCode(
          200,
          NotificationType.SUCCESS,
          'Report Cloned',
          'Your report has been cloned.'
        );

        this.create({ endpoint: ReportBuilderService.apiPath, body: { 'reportBuilderTitle': newReportName } })
          .pipe(
            mergeMap((res) => this.getReport(res.body.appActivityId)),
            mergeMap((newReport: ReportActivity) => {
              let state: (string | ReportBuilderFormJson)[]; // each element in the state array is either an activity ID (sheet), or JSON form data (draft)

              try {
                // parse cloned report's state
                state = JSON.parse(report.getMetaDataByKey('state'));
              } catch {
                console.warn('could not parse state!', report);
                const _sheets = report.getMetaDataByKey('sheets');
                if (_sheets) {
                  // default to sheets field if no previous state
                  state = _sheets.split(',');
                } else {
                  // this shouldn't really happen (unless an empty report was cloned - or any other failure
                  state = [];
                }
              }

              // if report does not belong to the same access group, do not clone drafts
              if (!report.isSameAccessGroup()) {
                state = state.filter(sheet => typeof sheet === 'string'); // drafts are objects, completed sheets are an activity id stored as string
              }

              state = state.map(sheet => {
                if (typeof sheet === 'string') {
                  // find the corresponding activity in report.sheets, and generate the form data for it
                  const sheetActivity = report.sheets.find(a => a.getId() === sheet);
                  const json = this.createForm(sheetActivity.getFormValues()).toJson();

                  // the sheet's most recent name is found in the metadata, not the original form values
                  json.name = sheetActivity.getName();
                  json.status = sheetActivity.getStatus();
                  return json;
                }
                // otherwise, it is a draft, and does not need any additional processing
                return sheet;
              });

              return this.updateEntityMeta(newReport, {
                name: newReportName,
                sheets: [], // no sheets, should all be drafts at this point
                state: JSON.stringify(state) // stringified state
              }).pipe(
                map(() => [newReport, state])
              );
            })
          )
          .subscribe(([newReport, sheets]: [ReportActivity, ReportBuilderFormJson[]]) => {
            ActivityService.refresh();
            this.mixpanelService.track(MixpanelEvent.REPORTS_CLONED, {
              'Application': this.config.getApplication().display,
              'Cloned Activity ID': id,
              'Name': newReport.getName(),
              'JSON': sheets,
              'Type': 'Report'
            });
            this.router.navigate([`app/report-builder/${newReport.getId()}`]);
          });
      });
  }

  // Sends the POST request to create a report
  // IMPORTANT: Another POST request should be sent upon success of this one (initializing the columns)
  public createReport(formData: ReportBuilderFormData): Observable<any> {
    this.overrideResponseCode(
      200,
      NotificationType.INFO,
      'Report Created',
      'Your report has been created and is now running.'
    );

    const payload = new ReportBuilderParameters(formData).toJson();

    return this.create({ endpoint: ReportBuilderService.apiPath, body: payload }).pipe(
      map(res => res.body),
      tap(res => {
        ActivityService.refresh();

        this.mixpanelService.track(MixpanelEvent.ACTIVITY_CREATED, {
          'Application': this.config.getApplication().display,
          'Activity ID': res['appActivityId'],
          'Columns': formData.columns.map(c => c.ref.toJson()),
          'Size': formData.size(),
          'JSON': formData.toJson(),
          'Name': formData.name,
          'Type': 'Report'
        });
        if (formData.sssChecked) {
          this.trackSSS(res['appActivityId'], formData, 'Report');
        }
      })
    );
  }

  public editSheet(formData: ReportBuilderFormData, reportId: string, sheetId: string): Observable<any> {
    const payload = new ReportBuilderParameters(formData).toJson();
    payload['id'] = sheetId;

    this.overrideResponseCode(
      200,
      NotificationType.SUCCESS,
      'Report Updated',
      'Your report has been updated and is now running'
    );

    return this.create({ endpoint: `${ReportBuilderService.apiPath}/${reportId}/sheet`, body: payload }).pipe(
      map(res => res.body),
      tap(json => {
        if (formData.sssChecked) { // if Same Store Sales checkbox is checked
          this.trackSSS(json['appActivityId'], formData, 'Sheet');
        }
      })
    );
  }

  public addSheetToReport(formData: ReportBuilderFormData, reportId: string, createdFrom: string): Observable<any> {
    this.overrideResponseCode(
      200,
      NotificationType.SUCCESS,
      'Sheet Added',
      'Your new sheet has been added and is now running'
    );

    const payload = new ReportBuilderParameters(formData).toJson();

    return this.create({ endpoint: ReportBuilderService.apiPath + '/' + reportId + '/sheet', body: payload }).pipe(
      map(res => res.body),
      tap(json => {
        this.mixpanelService.track(MixpanelEvent.ACTIVITY_CREATED, {
          'Application': this.config.getApplication().display,
          'Activity ID': json['appActivityId'],
          'Columns': formData.columns.map(c => c.ref.toJson()),
          'Size': formData.size(),
          'JSON': formData.toJson(),
          'Parent Report ID': reportId,
          'Created From': createdFrom,
          'Type': 'Sheet'
        });
        if (formData.sssChecked) { // if Same Store Sales checkbox is checked
          this.trackSSS(json['appActivityId'], formData, 'Sheet');
        }
      })
    );
  }

  // This is the POST request that should be sent after creating a RB entity
  // This will take each columns' app activity IDs (which were generated by the BE after the first POST call)
  // and updates the column names and orders based on those app activity IDs.
  public initializeSheetMetadata(
    formData: ReportBuilderFormData, sheetJson: any): Observable<HttpResponse<any>> {

    const newOrder = [];
    const namesMap = {};
    let metricIndex = 0;
    formData.columns.forEach(c => {
      if (c instanceof DimensionColumn) {
        newOrder.push(c.ref.id);
        namesMap[c.ref.id] = c.columnGroupName || '';
      } else if (c instanceof MetricColumn) {
        const appActivityId = sheetJson.columnOrder[metricIndex++];
        newOrder.push(appActivityId);
        namesMap[appActivityId] = c.columnGroupName || '';
      }
    });

    return this.updateSheet(sheetJson.appActivityId, {
      columnNames: namesMap,
      columnOrder: newOrder
    });
  }

  public getReport(reportId: string): Observable<ReportActivity> {
    return this.activityService.getActivity<ReportActivity>({
      id: reportId,
      resultType: ActivityResultType.NO_RESULTS
    });
  }

  public getSheet(sheetId: string, reportId: string): Observable<Activity> {
    return this.get({ endpoint: ReportBuilderService.apiPath + '/' + reportId + '/sheet/' + sheetId })
      .pipe(map(res => ActivityFactory.createActivity<Activity>({ activity: res[0] })));
  }

  // Updates the report's metadata fields with whatever payload given
  public updateSheet(sheetId: string, fields: any): Observable<HttpResponse<any>> {
    const payload = _.extend(
      { id: sheetId }, fields
    );
    return this.update({
      endpoint: ReportBuilderService.apiPath + '/' + sheetId,
      body: payload,
      type: ContentType.JSON,
      suppressNotification: true
    });
  }

  public updateEntityMeta(activity: Activity, meta: any): Observable<HttpResponse<any>> {
    return this.activityService.updateMetadata(activity, meta, true);
  }

  // Deletes the report
  public deleteReport(reportId: string): Observable<HttpResponse<any>> {
    return this.remove({
      endpoint: ReportBuilderService.apiPath + '/' + reportId,
      suppressNotification: true
    });
  }

  public deleteSheet(sheetId: string, reportId: string): Observable<HttpResponse<any>> {
    return this.remove({
      endpoint: ReportBuilderService.apiPath + '/' + reportId + '/sheet/' + sheetId,
      suppressNotification: true
    });
  }

  // Exports Report to CSV
  public exportSheetAsCSV(reportName: string, sheet: ReportBuilderSheet) {
    ExcelService.exportSheetAsCSV(reportName, sheet.gridVisualOptions);
  }

  public exportSheetAsExcel(reportName: string, sheets: ReportBuilderSheet[]) {
    // Prepare export parameters and data
    const params: ExcelExportMultipleSheetParams = {
      author: '',
      data: this.getMultipleSheetsData(sheets),
      fileName: ExcelService.generateFileName(reportName)
    };
    ExcelService.saveSheetsAsXLSX(params, sheets[0].gridVisualOptions?.apiRef().grid);
  }

  public async getCloudExportData(reportName: string, sheets: ReportBuilderSheet[]): Promise<Blob> {
    const params: ExcelExportMultipleSheetParams = {
        data: this.getMultipleSheetsData(sheets),
        fileName: ExcelService.generateFileName(reportName)
    };
    return sheets[0].gridVisualOptions?.apiRef().grid.api.getMultipleSheetsAsExcel(params);
  }

  // prepare Ag Grid ExcelExportMultipleSheetParams data
  public getMultipleSheetsData(sheets: ReportBuilderSheet[]): any[] {
    const data = [];
    sheets.forEach((sheet) => {
        data.push(sheet.gridVisualOptions?.apiRef().grid.api.getSheetDataForExcel({
          sheetName: ExcelService.sanitizeSheetName(sheet.name),
          processHeaderCallback: (params: ProcessHeaderForExportParams) => {
            return (ExcelService.stringToUnicode(params.column.getColDef().headerName));
          },
          processCellCallback: params => {
            return GridService.processCellCallback(params, sheet.gridVisualOptions.apiRef().colDefMeta);
          }
        }));
      });
    return data;
  }

  public overrideResponseCode(code: number, type: NotificationType, header: string, message: string): void {
    _.remove(this.overrideCodes, ['code', code]);
    this.overrideCodes.push(
      new ResponseCode(
        code,
        '<hr/>' + message,
        header,
        type
      )
    );
  }

  public drilldown(reportBuilderResult: ReportBuilderResultComponent, newDimension: CmsField, dataNode: any)
    : Observable<Activity> {
    const _obs = new Subject<Activity>(); // Internal observable used to return final response
    const srcSheet = reportBuilderResult.activeSheet as ReportBuilderSheet;
    const formData = srcSheet.getForm();
    const dateDims: CmsField[] = []; // Used for drilling into time series dimensions

    // Modify form data with new dimension & filters
    const newCols: FormColumn[] = [new DimensionColumn(formData, newDimension)];

    let newSSSChecked: boolean = formData.sssChecked;

    formData.columns.forEach(c => {
      if (c instanceof MetricColumn) {

        // Maintain all metric columns
        newCols.push(c);
      } else if (c instanceof DimensionColumn) {

        if (newSSSChecked && c.ref.field === SameStoreSalesService.STORE_ID) {
          newSSSChecked = false;
        }

        if (!c.ref.filter) {
          return console.warn('Dim has no filter!');
        }

        if (c.ref.filter === 'DATE') {
          dateDims.push(c.ref);
        } else {
          if (c.ref.id.includes('prod_upc_desc')) {
            dataNode[c.ref.id] = dataNode[c.ref.id].split('-')[0].trim();
          }

          let filterValue = UtilsService.processDrilldownValue(dataNode[c.ref.id], c.ref);

          // Remove existing relative filters
          _.remove(formData.globalFilters, f => f.id === c.ref.id);

          // Convert the current dimensions to filters
          const replacementFilter = new FilterSelection({
            id: c.ref.id,
            nulls: false,
            include: true,
            values: []
          });

          if (filterValue.toString().trim() === AppSiqConstants.nullDimReplacementString) {
            replacementFilter.nulls = true;
          } else {
            replacementFilter.values.push(filterValue);
          }
          formData.globalFilters.push(replacementFilter);
        }
      }
    });

    formData.sssChecked = newSSSChecked;

    if (dateDims.length) {
      formData.globalDateRange = this.generateDrilldownRange(dateDims, dataNode, formData.globalDateRange);

      // If the global date range changed, each of the year over year columns' date ranges also need to be adjusted
      // TODO: possibly refine this method. Currently it just subtracts a full year
      formData.columns
        .filter(c => c instanceof MetricColumn)
        .forEach((c: MetricColumn) => {
          if (c.yearOverYear) {
            const range = c.yearOverYear.compDateRange;
            range.begin = DatesService.add(formData.globalDateRange.begin, { years: -1 });
            range.end = DatesService.add(formData.globalDateRange.end, { years: -1 });
          }
        });
    }

    formData.name = ` Sheet ${reportBuilderResult.sheets.length + 1}`;
    formData.columns = newCols;
    let sheetId: string;

    this.addSheetToReport(formData, reportBuilderResult.report.getId(), 'Drilldown')
      .pipe(
        mergeMap(sheetJson => {
          sheetId = sheetJson.appActivityId;
          return this.initializeSheetMetadata(formData, sheetJson);
        }),
        mergeMap(() => this.getSheet(sheetId, reportBuilderResult.report.getId()))
      )
      .subscribe(sheetActivity => {
        _obs.next(sheetActivity);
        _obs.complete();
      });

    return _obs;
  }

  private notifyCompletedSheets(runningSheets: Activity[], newList: ReportActivity[]) {
    const notifyStatus: ActivityStatus[] = [ActivityStatus.READY, ActivityStatus.ERROR];
    runningSheets.forEach(oldSheet => {
      let newSheet: Activity;
      const report = newList.find(r => !!r.sheets.find(s => {
        if (s.getId() === oldSheet.getId()) {
          newSheet = s;
          return true;
        }
      }));
      if (newSheet && notifyStatus.includes(newSheet.getStatus())) {
        this.notificationService.success(
          'Your sheet <b>' + newSheet.getName() + '</b> is ready',
          'Sheet Complete',
          {
            data: {
              onClick: () => {
                this.router.navigate(['app/report-builder/' + report.getId()], {
                  queryParams: { s: newSheet.getId() }
                });
              }
            },
            enableHTML: true
          });
      }
    });
  }

  private generateDrilldownRange(dateDims: CmsField[], dataNode: any, dateRange: DateRangeInterface): DateRangeInterface {
    // If multiple time-series dimensions exist in the row node, we need to get the most granular dimension to generate a new date range
    const dateMappings = this.datesService.dateMappings;
    const smallestDim = _.minBy(dateDims, d => dateMappings.get(d.field).level);
    const _date = utcToZonedTime(Number(dataNode[smallestDim.id]), '-00:00');
    const _min = dateRange.begin;
    const _max = dateRange.end;

    let end: Date, start: Date;
    if ((smallestDim.field.includes('week_end_'))) {
      // Special case for week ending dims
      end = _date;
      start = DatesService.add(end, { days: -6 });
    } else {
      const timeUnit = dateMappings.get(smallestDim.field).unit;
      start = _date;
      end = DatesService.getEndOf(timeUnit)(start);
    }

    const dateRangeModel: DateRangeInterface = {
      begin: DatesService.isBefore(start, _min) ? _min : start,
      end: DatesService.isAfter(end, _max) ? _max : end
    };
    return dateRangeModel;
  }

  private trackSSS(activityId: string, formData: ReportBuilderFormData, type: 'Sheet' | 'Report') {
    this.mixpanelService.track(MixpanelEvent.FEATURE_SELECTED, {
      'Application': this.config.getApplication().display,
      'Feature': 'Same Store Sales',
      'Activity ID': activityId,
      'Columns': formData.columns.map(c => c.ref.toJson()),
      'Size': formData.size(),
      'JSON': formData.toJson(),
      'Type': type
    });
  }

  /**
   * Get count of drants in Report Builder's Activity
   * @param metaData
   * @return number
   */
  public static getCountOfDraftsInReportBuilderActivity(metaData: string): number {
    return JSON.parse(metaData).filter(el => typeof el === 'object').length;
  }

}
