import { CmsMetric } from '@siq-js/cms-lib';
import { BaseSiqComponent } from '@siq-js/angular-buildable-lib';
import { Activity } from 'app/activity/models/activity.model';
import { FieldReference } from 'app/core/models/field-reference';
import { StatResponse } from 'app/core/models/stat-response.model';
import { SiqFilter } from 'app/filter/models/interfaces';
import { PromoResultComponent } from 'app/siq-applications/modules/promo/components/promo-result/promo-result.component';
import { SovHelperComponent } from 'app/siq-applications/modules/promo/components/promo-result/sov-helper/sov-helper.component';
import { KPI, PromoPeriods, UnitTypes } from 'app/siq-applications/modules/promo/models/promo.enums';
import { DateRangeParameter } from '@siq-js/core-lib';
import { BehaviorSubject, Subject } from 'rxjs';
import { TakeRateHelperComponent } from 'app/siq-applications/modules/promo/components/promo-result/take-rate-helper/take-rate-helper.component';
import { SovResultComponent } from 'app/siq-applications/modules/promo/components/promo-result/sov-result/sov-result.component';
import { AnalysisType } from 'app/core/models/analysis-type.enum';

export interface SOVData {
  [dimKey: string]: SOVUnitData;
}
export interface SOVUnitData {
  absData: any[];
  pspwData: any[];
}
export interface PromoResult extends BaseSiqComponent {
  createDropdownOptions: (params: any) => void;
  generateFileName?: (activityName: string) => string;
  render$: Subject<void>;
  renderedOnce: boolean;
  render: () => void;
  setupTable: () => void;
  setupChart: () => void;
  updateChart?: (sr: StatResponse) => void;
  updateView: () => void;
}
export interface ActivityTypesListInterface {
  types: string[];
}

export interface UpcCountParams {
  analysisType: AnalysisType;
  dateRanges: DateRangeParameter[];
  productFilters: SiqFilter[];
}

export interface ComparisonGroupParam {
  id: string;
  name: string;
  filters: SiqFilter[];
}

export interface PromoSheetResult extends PromoResult {
  helperParams: any;
  isActive: () => boolean;
  setActive: (...args) => void;
}

export interface SourceOfVolumeParam {
  sovId: string;
  comparisonGroupId: string;
  dimension: FieldReference;
}
export interface PromoParams {
  name: string;
  promoType?: string;
  upcFilters: SiqFilter[];
  locationFilters?: SiqFilter[];
  promoDateRange: DateRangeParameter;
  prePromoDateRange: DateRangeParameter;
  yoyDateRange: DateRangeParameter;
  customPromoDateRange?: DateRangeParameter;
  comparisonGroups?: ComparisonGroupParam[];
  sourcesOfVolume?: SourceOfVolumeParam[];
  formValues: Object;
  retailers: string[];
  id?: string;
  analysisType: AnalysisType;
}

export interface KpiModel {
  [key: string]: { // PROMO, PROD_CATEGORY, etc
    [kpi in KPI]: { // KPI facts
      [PromoPeriods.PRE]: number, [PromoPeriods.YOY]: number, [PromoPeriods.PROMO]: number // period KPI values
    }
  };
}

export interface TakeRateHelperParams {
  data: any;
  activity: Activity;
  activityPSPW: Activity;
  basketSizes: string[];
  facts: CmsMetric[];
  initState: TakeRateHelperState;
  helperComponent: TakeRateHelperComponent;
  unitType: UnitTypes;
  parent: PromoResultComponent;
}

export interface TakeRateHelperState {
  factKey: CmsMetric;
  basketSize: string;
}

export interface HasDynamicMetrics {
  // support for dynamic metrics such as per-store-per-week
  factKey: CmsMetric;
  facts: CmsMetric[];

  unitType: UnitTypes;
  unitTypes: typeof UnitTypes;

  setupFacts: () => void; // fn to change/filter compatible metrics AFTER the unit type has been changed
  updateUnitType: () => void; // fn to be called in template when unit type is changed - should call setupFacts() inside
}
export interface SalesByDayTSDataSet {
  customDatesData?: any[];
  preDatesData: any[];
  promoYOYData: any[];
  dataMap: Map<number, number>;
}

export interface SOVHelperData {
  distribution: {[s in string]: any[]};
  distributionPSPW?: {[s in string]: any[]};
  timeseries: any[];
}
export interface SOVDrilldown {
  activity?: Activity;
  data?: SOVHelperData;
  id: string;
  label: string;
}

export interface SOVHelperParams {
  // Fields that are passed into the drawer component
  drilldowns: SOVDrilldown[];
  drilldownTrigger$: BehaviorSubject<string>;
  helperComponent: SovHelperComponent;
  parent: SovResultComponent;
}
