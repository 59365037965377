import { Injectable } from '@angular/core';
import { AffinitiesFormData, AffinitiesFormJson } from 'app/siq-applications/modules/affinities/models/form/affinities-form-data.model';
import { HttpClient, HttpResponse } from '@angular/common/http';
import * as _ from 'lodash';
import { SiqHttpService } from 'app/core/services/siq-http/siq-http.service';
import { ActivityResultType, ActivityService } from 'app/activity/services/activity.service';
import { MixpanelService } from 'app/core/services/mixpanel/mixpanel.service';
import { AppDataset } from 'app/siq-applications/modules/shared/models/app-dataset.model';
import { DatesService } from 'app/siq-forms/modules/dates/services/dates.service';
import { AffinitiesParameters, AffinitiesDrillDownParams } from 'app/siq-applications/modules/affinities/models/form/affinities-parameters';
import { UtilsService } from 'app/core/services/utils/utils.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, tap } from 'rxjs';
import { MixpanelEvent } from 'app/core/services/mixpanel/mixpanel-event.enum';
import { AffinitiesConfig, AffinitiesFacts } from 'app/siq-applications/modules/affinities/models/affinities-config.model';
import { Activity } from 'app/activity/models/activity.model';
import { ApplicationHash, WeekEndingDay } from '@siq-js/core-lib';
import {
  NotificationService,
  NotificationType,
  ResponseCode,
  ResponseCodes,
  ResponseCodesConfig
} from '@siq-js/angular-buildable-lib';
import { CmsService } from 'app/core/services/cms/cms.service';
import { CmsField, CmsMetric } from '@siq-js/cms-lib';
import { FilterSelection } from 'app/filter/models/filter-selection';
import { AppRequest } from 'app/siq-applications/modules/shared/models/app-request.model';
import { EnvConfigService } from 'app/core/services/env-config/env-config.service';
import { Router } from '@angular/router';
import { SiqApplicationService } from 'app/siq-applications/modules/shared/services/siq-application.service';
import {
  AggregationType,
  ExcelService,
  IStatResponse,
  TextColorType,
  VISUAL_CONFIG,
  VisualOptions,
} from '@siq-js/visual-lib';
import { GridService } from 'libs/visual-lib/src/lib/modules/grid/services/grid.service';
import { MatDialogRef } from '@angular/material/dialog';
import { AffinitiesDrilldownComponent } from 'app/siq-applications/modules/affinities/components/affinities-drilldown/affinities-drilldown.component';
import { AffinitiesActivity } from 'app/siq-applications/modules/affinities/models/affinities-activity.model';
import { DateRangeInterfaceType } from 'app/siq-forms/modules/dates/models/interfaces';

@Injectable()

export class AffinitiesService extends SiqHttpService {

  public static canDrill(form: AffinitiesFormData) {
    if (form.affinityType && !form.secondaryAffinityType) {
      return !!form.affinityType.filter
    }
    if (form.affinityType && form.secondaryAffinityType) {
      return !!form.affinityType.filter && !!form.secondaryAffinityType.filter;
    }
    return false;
  }

  public static generateColumnData(sr: IStatResponse): any[] {
    // manually add left & right metrics
    sr.facts.unshift(new CmsMetric({
      id: AffinitiesFacts.RIGHT,
      display: AffinitiesFacts.RIGHT,
      active: true,
      aggType: AggregationType.NO_AGG,
      type: 'STRING'
    }));
    sr.facts.unshift(new CmsMetric({
      id: AffinitiesFacts.LEFT,
      display: AffinitiesFacts.LEFT,
      active: true,
      aggType: AggregationType.NO_AGG,
      type: 'STRING'
    }));

    // add left and right metrics values
    sr.getValues().forEach(row => {
      const dVIndex = row[0];
      const dV = sr.getDimensionValues()[0][dVIndex];
      const separator = dV.indexOf('|');
      row.splice(sr.getDimensions(true).length, 0, dV.substring(0, separator), dV.substring(separator + 1));
    });
    return GridService.jobToArray(sr);
  }

  public static Activities$: BehaviorSubject<AffinitiesActivity[]>;
  public static readonly apiPath = 'app/affinity-engine';
  private static readonly apiPathDetail = AffinitiesService.apiPath + '/detail';
  public clonedActivity: Activity;

  private overrideCodes: ResponseCode[] = [];

  constructor(
    protected http: HttpClient,
    protected notificationService: NotificationService,
    private activityService: ActivityService,
    private config: AffinitiesConfig,
    private mixpanelService: MixpanelService,
    private datesService: DatesService,
    private router: Router,
    private siqApplicationService: SiqApplicationService,
    private utilsService: UtilsService,
  ) {
    super(http, notificationService);
    if (!AffinitiesService.Activities$) {
      AffinitiesService.Activities$ = ActivityService.createStream<AffinitiesActivity>(
        activities => activities.filter(a => a.getAppId() === ApplicationHash.AFFINITIES && (!a.isMine() || (a.isMine() && !a.isSharedOrScheduled()))).map(a => a as AffinitiesActivity)
      );
    }
  }

  // Clone an affinities form using formValues from an activity
  public cloneReport(id: string) {
    this.getReport(id, ActivityResultType.NO_RESULTS)
      .subscribe(activity => {
        this.clonedActivity = activity;
        this.router.navigate(['/app/affinities/~']);
      });
  }

  public colorizeFact(factId: string, val: number, textColorType: TextColorType, formatter?: (obj, t?) => any, type?: string): boolean {
    if (_.isNil(formatter)) {
      const m = _.find(VISUAL_CONFIG.VISUAL_DATA_TYPES, { type: type });
      if (m) formatter = m.formatter;
    }

    if (_.isNil(formatter)) return false;

    // The valueFormatter may truncate decimals resulting in _val not being zero, but zero being displayed
    let formatted = formatter({ value: val });
    const lastChar = formatted.substring(formatted.length - 1);
    if (lastChar === '%') {
      formatted = formatted.substring(0, formatted.length - 1); // trim the % symbol
    }
    formatted = Number(formatted.replace(/,/g, ''));

    switch (textColorType) {
      case TextColorType.SUCCESS:
        switch (factId) {
          case AffinitiesFacts.OCCURRENCE:
          case AffinitiesFacts.SUPPORT:
          case AffinitiesFacts.CONFIDENCE:
            return false;
          case AffinitiesFacts.ADDED_VALUE:
            return formatted > 0;
          case AffinitiesFacts.LIFT:
          case AffinitiesFacts.CONVICTION:
            return formatted > 1;
        }
        break;

      case TextColorType.WARN:
        switch (factId) {
          case AffinitiesFacts.OCCURRENCE:
          case AffinitiesFacts.SUPPORT:
          case AffinitiesFacts.CONFIDENCE:
            return false;
          case AffinitiesFacts.ADDED_VALUE:
            return formatted < 0;
          case AffinitiesFacts.LIFT:
          case AffinitiesFacts.CONVICTION:
            return formatted > 0 && formatted < 1;
        }
        break;
    }
  }

  public createDrillDownRequest(a: Activity, formData: AffinitiesFormData, rowData: object): Observable<HttpResponse<any>> {
    let params = _.cloneDeep(a.getJob().getParams()) as AffinitiesDrillDownParams;
    const formValues = JSON.parse(_.cloneDeep(a.formValues)) as AffinitiesFormJson;
    let leftData = rowData[AffinitiesFacts.LEFT].val;
    let rightData = rowData[AffinitiesFacts.RIGHT].val;
    // if the affinity type is UPC description, only take the number inside the "()""
    if (formValues.affinityType.id.includes('prod_upc_desc')) {
      leftData = UtilsService.getUpcFromUpcDescription(leftData);
      // Affinity is symmetric, type of UPC description. Number And Description are mixed
      if (_.isNil(formValues.secondaryAffinityType)) {
        rightData = UtilsService.getUpcFromUpcDescription(rightData);
      }
    }
    // Asymmetric - Second affinity type is UPC
    if (formValues.secondaryAffinityType?.id.includes('prod_upc_desc')) {
      rightData = UtilsService.getUpcFromUpcDescription(rightData);
    }
    console.log('-----Drilling into-----:', leftData + '  AND  ' + rightData);
    // add the selected row data to the left & right filters for drill down
    // attach left row data to the left filters
    const leftFilter = UtilsService.paramify(
      new FilterSelection({
        id: formValues.affinityType.id,
        values: [leftData],
        include: true,
        nulls: false
      })
    );
    leftFilter['basket'] = true;
    leftFilter.n = this.convertUPC(leftFilter.n);
    params.leftFilters.push(leftFilter);
    // attach right row data to the right filters
    const rightFilter = UtilsService.paramify(
      new FilterSelection({
        id: formValues.secondaryAffinityType?.id ?? formValues.affinityType.id, // for asymmetric affinity, use the secondaryAffinityType.id
        values: [rightData],
        include: true,
        nulls: false
      })
    );
    rightFilter['basket'] = true;
    rightFilter.n = this.convertUPC(rightFilter.n);
    params.rightFilters.push(rightFilter);
    // AG-275: additional requirements for top 5 request's right filter
    const topFiveRightFilter = _.cloneDeep(rightFilter);
    const topFiveLeftFilter = _.cloneDeep(leftFilter);

    delete params.affinityType;
    delete params.secondaryAffinityType;

    // make 7 copies for the drill down requests
    let p0: AffinitiesDrillDownParams = _.cloneDeep(params);
    let p1: AffinitiesDrillDownParams = _.cloneDeep(params);
    let p2: AffinitiesDrillDownParams = _.cloneDeep(params);
    let p3: AffinitiesDrillDownParams = _.cloneDeep(params);
    let p4: AffinitiesDrillDownParams = _.cloneDeep(params);
    let p5: AffinitiesDrillDownParams = _.cloneDeep(params);
    let p6: AffinitiesDrillDownParams = _.cloneDeep(params); // top 5

    p0.affinityDetailType = 'AVG_BASKET_AMT'; // follow what V1 is doing

    p1.affinityDetailType = 'AVG_BASKET_COUNT';

    p2.dimensions = [UtilsService.paramify(CmsService.get().findEntity<CmsField>('yearmon'))];
    p2.facts = [UtilsService.paramify(CmsService.get().findEntity<CmsMetric>('TOTAL_AMOUNT'))];

    p3.dimensions = [UtilsService.paramify(CmsService.get().findEntity<CmsField>('yearmon'))];
    p3.facts = [UtilsService.paramify(CmsService.get().findEntity<CmsMetric>('TOTAL_QUANTITY'))];

    p4.dimensions = [UtilsService.paramify(CmsService.get().findEntity<CmsField>('hour'))];
    p4.facts = [UtilsService.paramify(CmsService.get().findEntity<CmsMetric>('PERCENT_TOTAL_QUANTITY'))];

    p5.dimensions = [UtilsService.paramify(CmsService.get().findEntity<CmsField>('dayofweek'))];
    p5.facts = [UtilsService.paramify(CmsService.get().findEntity<CmsMetric>('PERCENT_TOTAL_QUANTITY'))];

    // additional requirements mentioned in AG-275
    p6.dimensions = [
      UtilsService.paramify(CmsService.get().findEntity<CmsField>(
        formValues.secondaryAffinityType?.id ?? formValues.affinityType.id
      ))
    ];
    p6.rightFilters.pop();
    p6.rightFilters.push(topFiveRightFilter);
    p6.leftFilters.pop();
    p6.leftFilters.push(topFiveLeftFilter);
    p6.leftFilters.push(
      {
        n: 'AFFINITY_DETAIL_OVERLAPPING_DIMENSIONS',
        ta: null,
        r: null,
        in: [],
        out: [],
        inNull: false,
        outNull: false,
        // ? basket: true,
      }
    );
    p6.facts = [UtilsService.paramify(CmsService.get().findEntity<CmsMetric>('NUM_TRANSACTIONS'))]; // !new requirement
    p6['limit'] = 5; // copying V1;

    const appRequest = new AppRequest(
      [
        new AppDataset('Average Basket Amount', p0),
        new AppDataset('Average Basket Count', p1),
        new AppDataset('Monthly Performance', p2),
        new AppDataset('Monthly Performance', p3),
        new AppDataset('Hour of Day', p4),
        new AppDataset('Day of Week', p5),
        new AppDataset('Top 5', p6)
      ],
      formValues,
      undefined,
      true
    );
    appRequest.setMetaDataEntry('name', 'Affin-drilldown');
    appRequest.setMetaDataEntry('analysisType', formData.getAnalysisType());
    return this.siqApplicationService.createActivity(appRequest, AffinitiesService.apiPathDetail, true);
  }

  /**
   * Create AffinitiesFormData
   * Optional field json can be passed in - allowing it to be repopulated from the formValues field of the report
   * If no json passed in, create a blank formData
   * @param fv: AffinitiesFormJson used to create AffinitiesFormData
   */
  public createForm(fv?: AffinitiesFormJson): AffinitiesFormData {
    const formData = new AffinitiesFormData();
    const cmsConfig = CmsService.get();

    if (fv) {
      formData.name = fv.name;
      formData.schema = fv.schema;
      formData.isCloned = fv.isCloned;
      formData.dateRanges = DatesService.isDynamic(fv.dateRanges)
        ?
        {
          end: new Date(DatesService.dateStoMS(fv.dateRanges.end)),
          begin: new Date(DatesService.dateStoMS(fv.dateRanges.begin)),
          dynamicBegin: fv.dateRanges.dynamicBegin,
          dynamicEnd: fv.dateRanges.dynamicEnd,
        }
        :
        {
          end: new Date(DatesService.dateStoMS(fv.dateRanges.end)),
          begin: new Date(DatesService.dateStoMS(fv.dateRanges.begin))
        };
      formData.dateRanges.type = DateRangeInterfaceType.POPULATED;
      formData.affinityType = cmsConfig.findEntity<CmsField>(fv.affinityType.id);
      formData.filters = fv.filters.map(f => new FilterSelection(f));
      formData.leftFilters = fv.leftFilters.map(f => new FilterSelection(f));
      formData.rightFilters = fv.rightFilters.map(f => new FilterSelection(f));
      // asymmetric affinity
      if (fv.secondaryAffinityType) {
        formData.secondaryAffinityType = cmsConfig.findEntity<CmsField>(fv.secondaryAffinityType.id);
      }
      if (fv.weekEndingDay) {
        formData.weekEndingday = fv.weekEndingDay;
      } else { // If there's no weekEndingDay, means it's an old report created before WE project.
        formData.weekEndingday = WeekEndingDay.OLD_REPORT; // According to ICE-2295: Old reports before WE project used Saturday as default WE. RB TimeBrekDown used Sunday as default.
      }
    }

    return formData;
  }

  /**
   * Sends the POST request to create a report
   */
  public createReport(formData: AffinitiesFormData): Observable<any> {
    this.overrideCodesForCreateRequests(formData);

    const params: AffinitiesParameters = {
      affinityType: UtilsService.paramify(formData.affinityType),
      filters: formData.filters.map(f => UtilsService.paramify(f)),
      leftFilters: formData.leftFilters.map(f => UtilsService.paramify(f)),
      rightFilters: formData.rightFilters.map(f => UtilsService.paramify(f)),
      dateRanges: [DatesService.paramify(formData.dateRanges, formData.weekEndingday)]
    };
    // asymmetric affinity
    if (formData.secondaryAffinityType) {
      params.secondaryAffinityType = UtilsService.paramify(formData.secondaryAffinityType);
    }
    // add retailer filter in DTO if in single retailer analysis mode
    if (formData.schema !== EnvConfigService.getConfig().primaryEntity) {
      const model = new FilterSelection({
        id: CmsService.RETAILER_FIELD_ID,
        values: [formData.schema],
        include: true,
        nulls: false
      });
      params.filters.unshift(UtilsService.paramify(model));
    }

    // if name is not set(undefined), use default value
    if (_.isNil(formData.name)) {
      formData.name = Activity.ActivityPlaceholder;
    }

    let metaData = new Map<string, string>();
    metaData.set('name', formData.name);
    metaData.set('analysisType', formData.getAnalysisType());

    const payload: AppRequest = new AppRequest([new AppDataset(formData.name, params)], formData.toJson(), metaData);

    return this.create({ endpoint: AffinitiesService.apiPath, body: payload.asJsonObject() })
      .pipe(
        map(res => res.body),
        tap(res => {
          ActivityService.refresh();
          this.mixpanelService.track(MixpanelEvent.ACTIVITY_CREATED, {
            'Application': this.config.getApplication().display,
            'Activity ID': res['appActivityId'],
            'JSON': formData.toJson(),
            'Name': formData.name,
            'Type': 'Report'
          });
        })
      );
  }

  private overrideCodesForCreateRequests(formData: AffinitiesFormData) {
    if (formData.isCloned) {
      this.overrideResponseCode(
        200,
        NotificationType.SUCCESS,
        'Report Cloned',
        'Your report has been cloned.'
      );
    } else {
      this.overrideResponseCode(
        200,
        NotificationType.SUCCESS,
        'Report Created',
        'Your report has been created and is now running.'
      );
    }
  }

  public getConfig(): AffinitiesConfig {
    return this.config;
  }

  public getReport(reportId: string, resultType: ActivityResultType = ActivityResultType.WITH_RESULTS): Observable<Activity> {
    return this.activityService.getActivity<Activity>({
      id: reportId,
      resultType: resultType
    });
  }

  public getResponseCodes(responseCodesConfig: ResponseCodesConfig): ResponseCodes {
    return new ResponseCodes(this.overrideCodes);
  }

  public openDrilldownModal(a: Activity, formData: AffinitiesFormData, rowData: object): MatDialogRef<AffinitiesDrilldownComponent> {
    let dialogRef: MatDialogRef<AffinitiesDrilldownComponent>;

    dialogRef = this.utilsService.openModal(
      AffinitiesDrilldownComponent,
      {
        activity: a,
        formData: formData,
        rowData: rowData,
        parent: parent
      },
      UtilsService.MODAL_CONFIG_LARGE
    );

    this.mixpanelService.track(MixpanelEvent.FEATURE_SELECTED, {
      'Application': this.config.getApplication().display,
      'Feature': 'Drill Down'
    });

    return dialogRef;
  }

  public overrideResponseCode(code: number, type: NotificationType, header: string, message: string): void {
    _.remove(this.overrideCodes, ['code', code]);
    this.overrideCodes.push(
      new ResponseCode(
        code,
        '<hr/>' + message,
        header,
        type
      )
    );
  }

  private convertUPC(id: string): string { // ICE-216 Petr says still need further investigation
    switch (id) {
      case 'prod_upc':
      case 'prod_upc_desc':
        return 'upc';
      default:
        return id;
    }
  }

  public exportSheetAsCSV(reportActivity: Activity, gridVisualOptions: VisualOptions) {
    ExcelService.exportSheetAsCSV(reportActivity.getName(), gridVisualOptions);
    
    this.mixpanelService.track(MixpanelEvent.REPORTS_EXPORTED, {
      'Application': this.config.getApplication().display,
      'Report ID': reportActivity.getId(),
      'Report Name': reportActivity.getName(),
      'File Type': 'csv',
      'Export Type': 'report'
    });
  }

  public exportSheetAsExcel(reportActivity: Activity, gridVisualOptions: VisualOptions) {
    ExcelService.saveSheetAsXLSX(reportActivity.getName(), gridVisualOptions);

    this.mixpanelService.track(MixpanelEvent.REPORTS_EXPORTED, {
      'Application': this.config.getApplication().display,
      'Report ID': reportActivity.getId(),
      'Report Name': reportActivity.getName(),
      'File Type': 'xlsx',
      'Export Type': 'report'
    });
  }

}
