import { Component, Injector } from '@angular/core';
import { IHeaderParams, GridComponent, GridService, ColumnState, DateModel, FilterNumberTypes } from 'libs/visual-lib/src';
import { Subject } from 'rxjs';
import * as _ from 'lodash';
import { takeUntil } from 'rxjs';
import { BaseSiqComponent, CapitalizePipe } from '@siq-js/angular-buildable-lib';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { CmsField, CmsMetric } from '@siq-js/cms-lib';

@Component({
  selector: 'siq-js-column-header',
  templateUrl: './column-header.component.html',
  styleUrls: ['./column-header.component.scss']
})
export class ColumnHeaderComponent extends BaseSiqComponent implements IHeaderAngularComp {

  /**
   * THIS IS A PORTING OF:
   * apps/dashboard/src/apps/visual/components/cell-renderers/visual-table-column-header/visual-table-column-header.componennt.ts
   */
  public model: {
    header: string,
    tooltip?: string,
    sort: ColumnState['sort'],
    filterApplied: string
  };

  public disableFilter: boolean;
  private colId: string;
  private params: IHeaderParams;
  private parent: GridComponent;
  private _unsub$: Subject<void> = new Subject<void>();

  constructor(
    private injector: Injector
  ) {
    super();
    this.parent = this.injector.get(GridComponent);
  }

  agInit(params: IHeaderParams) {
    if (params.column.isPivotActive() || params.column.getColId() === GridService.AG_GRID_AUTOCOLUMN_ID) {
      this.disableFilter = true;
    } else {
      if (params.api.isPivotMode()) {
        // GridComponent is in pivotMode; Check the "ref" in this.parent.colDefMeta for this column (pivotValueColumn)
        const ref = _.get(this.parent.colDefMeta.get(params.column.getColDef().pivotValueColumn?.getColId()), 'ref');

        // If ref is a CmsMetric, and the GridComponent is in pivotMode; Hide the filter icon/functionality
        if (ref && ref instanceof CmsMetric) {
          this.disableFilter = true;
        }
      }
    }

    let initColumnState = params.api.getColumnState().find(e => e.colId === params.column.getId());
    if (!initColumnState) {
      const pivotSort = params.api.getAllGridColumns().find(e => e.getColId() === params.column.getId()).getSort();
      if(pivotSort) {
        initColumnState = {colId: params.column.getId(), sort: pivotSort};
      }
    }

    this.model = {
      header: params.displayName,
      sort: initColumnState ? initColumnState.sort : null,
      filterApplied: null
    };

    if (this.parent.tooltipsMap) {
      let mappedTooltip = this.parent.tooltipsMap.get(params.displayName);
      if (mappedTooltip) {
        if (typeof mappedTooltip === 'string') {
          this.model.tooltip = mappedTooltip;
        } else {
          // This used to accept param of "Visualization", but that model no longer exists.
          // Passing gridVisualOptions for now as that can get additional fields added if needed.
          this.model.tooltip = mappedTooltip(this.parent.gridSettings.gridVisualOptions);
        }
      }
    }

    this.params = params;
    this.colId = params.column.getId();

    // If the table is not sorted through this column, set the model's sort to null
    this.parent.sortObs$
    .pipe(takeUntil(this.unsub$))
    .subscribe(sortModels => {
      const sortModel = _.find(sortModels, e => e.colId === this.colId);
      if (sortModel) {
        this.model.sort = sortModel.sort;
      } else {
        this.model.sort = null;
      }
    });

    // Update the filterApplied model based on whether this key is present in the new filter model
    this.parent.filterObs$
    .pipe(takeUntil(this.unsub$))
    .subscribe((filterModel: any) => {
      let filter = filterModel[this.colId];
      if (filter && filter.type && filter.filter) {
        //text filter
        this.model.filterApplied = new CapitalizePipe().transform(filter.type) + ' "' + filter.filter + '"';
      } else if (filter && filter.type && filter.value != null && filter.value != undefined && (filter.value?.toString()?.trim()?.length > 0)) {
        //number filter
        this.model.filterApplied = new CapitalizePipe().transform(filter.type) + ' ' + filter.value;
      } else if (filter && filter.filterType && filter.model && (filter.model as DateModel).isValid()) {
        //date filter
        this.model.filterApplied = "Filter applied";
      } else if (filter && filter.type == FilterNumberTypes.IN_RANGE && _.isFinite(filter.rangeLow) && _.isFinite(filter.rangeHigh)) {
        //range filter
        this.model.filterApplied = "From " + filter.rangeLow + ' to ' + filter.rangeHigh;
      } else {
        this.model.filterApplied = null;
      }
    });
  }

  refresh(params: IHeaderParams): boolean {
    return true;
  }

  showMenu(event) {
    this.params.showColumnMenu(event.target);
  }

  // Cycles from desc sort -> asc sort -> no sort
  toggleSort() {
    let currentSort = this.model.sort;
    if (currentSort === 'desc') {
      this.model.sort = 'asc';
    } else if (currentSort === 'asc') {
      this.model.sort = null;
    } else {
      this.model.sort = 'desc';
    }

    const newColumnState = this.params.api.getColumnState();
    const ifExists = _.find(newColumnState, e => e.colId === this.colId);
    if (ifExists) {
      ifExists.sort = this.model.sort;
    } else {
      newColumnState.splice(0,newColumnState.length);
      newColumnState.push({
        colId: this.colId,
        sort: this.model.sort
      });
    }

    this.params.setSort(this.model.sort, false);

    newColumnState.forEach(columnState => {
      if(columnState.colId !== this.colId){
        columnState.sort = null;
      }
    });

    this.parent.sortObs$.next(newColumnState);
  }

}
