import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { BaseSiqComponent } from '@siq-js/angular-buildable-lib';
import { Activity, ActivityStatus } from 'app/activity/models/activity.model';
import { BehaviorSubject, of } from 'rxjs';
import { AffinitiesConfig } from 'app/siq-applications/modules/affinities/models/affinities-config.model';
import { AffinitiesService } from 'app/siq-applications/modules/affinities/services/affinities.service';
import { ActivityService } from 'app/activity/services/activity.service';
import { CloudExportService } from 'app/core/modules/cloud-export/services/cloud-export.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AsyncStatusService } from 'app/core/services/async-status/async-status.service';
import { MixpanelService } from 'app/core/services/mixpanel/mixpanel.service';
import { StatResponse } from 'app/core/models/stat-response.model';
import { takeUntil, distinctUntilChanged, delay, switchMap, finalize, filter, takeWhile } from 'rxjs';
import { MixpanelEvent } from 'app/core/services/mixpanel/mixpanel-event.enum';
import { AffinitiesGridParams, AffinitiesGridProcessor } from 'app/siq-applications/modules/affinities/components/affinities-result/affinities-grid-processor';
import { VisualService } from 'libs/visual-lib/src/lib/services/visual.service';
import { QueryModeComponent } from 'app/core/components/query-mode-component/query-mode.component';
import { AffinitiesFormData } from 'app/siq-applications/modules/affinities/models/form/affinities-form-data.model';
import { ActivityGridOptionsMenu } from 'app/siq-applications/modules/shared/components/activity-grid-options-menu/activity-grid-options-menu.component';
import { SharingService } from 'app/activity/modules/sharing/services/sharing.service';
import { EmitterService } from 'app/core/services/emitter/emitter.service';
import { Location } from '@angular/common';
import { CloudExportable } from 'app/core/modules/cloud-export/models/cloud-export.interface';
import { VisualOptions } from '@siq-js/visual-lib';

@Component({
  selector: 'siq-js-affinities-result',
  templateUrl: './affinities-result.component.html',
  styleUrls: ['./affinities-result.component.scss']
})
export class AffinitiesResultComponent extends BaseSiqComponent implements OnInit, CloudExportable {

  @ViewChild('queryMode') queryMode: QueryModeComponent;

  // If you modify customOptionMenuItems, make sure to modify handleOptionMenuClick() function accordingly
  public customOptionMenuItems: string[] = [
    ActivityGridOptionsMenu.AUTOSIZE_COLUMNS,
    ActivityGridOptionsMenu.FIT_COLUMNS_TO_SCREEN,
    ActivityGridOptionsMenu.DOWNLOAD_CSV,
    ActivityGridOptionsMenu.DOWNLOAD_EXCEL,
    ActivityGridOptionsMenu.SHARE_SCHEDULE,
    ActivityGridOptionsMenu.CLONE_CRITERIA
  ];

  // only items present in customOptionMenuItems, handleOptionMenuClick() can be used
  public errorAndInvalidOptionMenuItems: string[] = [
    ActivityGridOptionsMenu.CLONE_CRITERIA
  ];

  public advancedMetrics = false;
  public applicationName: string = null;
  public formData: AffinitiesFormData;
  public gridParams: AffinitiesGridParams;
  public gridProcessor = AffinitiesGridProcessor.processor;
  public isCloudExportable: boolean;
  public options: VisualOptions[];
  public readyForExport$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false); // if grid is ready to be exported
  public reportActivity: Activity;
  public results: StatResponse[];
  public statusEnums = ActivityStatus;
  public status: ActivityStatus;
  private NOT_FOUND = 'not-found';
  private reportId: string;
  public showSpinner: boolean;

  constructor(
    public activityService: ActivityService,
    public affinitiesService: AffinitiesService,
    public cloudExportService: CloudExportService,
    public config: AffinitiesConfig,
    public route: ActivatedRoute,
    public router: Router,
    private asyncStatusService: AsyncStatusService,
    private mixpanelService: MixpanelService,
    private location: Location,
    private sharingService: SharingService
  ) {
    super();
  }

  attachCloudData(collection: VisualOptions[]) {
    if (!this.isCloudExportable) return;
    this.cloudExportService.attachCloudData(collection);
  }

  async ngOnInit() {
    await this.asyncStatusService.isReady({ cms: true, envConfig: true });

    this.showSpinner = true;
    this.route.paramMap // check URL
      .pipe(
        takeUntil(this.unsub$)
      )
      .subscribe(params => {
        this.reportId = params.get('id');
        this.poll();
      });

    this.isCloudExportable = await CloudExportService.isExportable(this);
    if (this.isCloudExportable) {
      CloudExportService.setReadyForExportSubscription(this.readyForExport$, this);
    }
  }

  /**
   * Redraw the rows on window:focus to prevent cells/columns that were being rendered overlapping.
   **/
  @HostListener('window:focus', ['$event'])
  onFocus(event: FocusEvent): void {
    this.redrawRows();
  }

  public showAdvancedMetrics(event?: any): void {

    if (event && event.checked) {
      this.advancedMetrics = true;
    }

    let key = this.advancedMetrics ? 'advanced' : 'standard';
    let newFacts = this.config.factMappings.get(key);

    //track only when triggered by user from Material component
    if(event.source) {
      this.mixpanelService.track(MixpanelEvent.FEATURE_SELECTED, {
        'Application': this.config.getApplication().display,
        'Feature': 'Advanced Metrics',
        'Usage': key
      });
    }

    // setting advancedMetrics inside selectFacts to save it in one call to avoid differences between table state and advancedMetrics state
    VisualService.selectFacts(
      newFacts,
      this.gridParams.gridVisualOptions,
      state => {
        state.advancedMetrics = this.advancedMetrics;
      }
    );
  }

  private init(reportActivity: Activity) {
    this.reportActivity = reportActivity;
    this.formData = this.affinitiesService.createForm(JSON.parse(reportActivity.formValues));
    this.queryMode.setSchema(this.formData.schema);
    setTimeout(() => {
      this.mixpanelService.track(MixpanelEvent.RESULTS_VIEWED, {
        'Application': this.config.getApplication().display,
        'Application ID': this.config.appId,
        'Application Version': this.config.version,
        'Activity ID': reportActivity.getId(),
        'Viewed From': 'Application'
      });
    });

    this.activityService.markViewed(this.reportActivity, this.route.snapshot.queryParamMap.get('source'));

    if (!this.reportActivity.isMine()) { // user is not report owner, cannot share/schedule report
      this.customOptionMenuItems = [
        ActivityGridOptionsMenu.AUTOSIZE_COLUMNS,
        ActivityGridOptionsMenu.FIT_COLUMNS_TO_SCREEN,
        ActivityGridOptionsMenu.DOWNLOAD_CSV,
        ActivityGridOptionsMenu.DOWNLOAD_EXCEL,
        ActivityGridOptionsMenu.CLONE_CRITERIA
      ];
    }
  }

  private processReport() {
    this.results = this.reportActivity.getJobs().map(job => job.getResponse());
    this.gridParams = {
      parent: this,
      statResponse: this.results[0],
      readyForExport$: this.readyForExport$
    };
    this.gridParams.gridVisualOptions = AffinitiesGridProcessor.generateGridVisualOptions(this.gridParams);
  }

  handleOptionMenuClick(clickedItem: string) {
    switch (clickedItem) {
      case ActivityGridOptionsMenu.AUTOSIZE_COLUMNS.trim():
        this.autoSize();
        break;
      case ActivityGridOptionsMenu.FIT_COLUMNS_TO_SCREEN.trim():
        this.fitColumnsToScreen();
        break;
      case ActivityGridOptionsMenu.DOWNLOAD_CSV.trim():
        this.exportSheetCSV();
        break;
      case ActivityGridOptionsMenu.DOWNLOAD_EXCEL.trim():
        this.exportSheetXLSX();
        break;
      case ActivityGridOptionsMenu.SHARE_SCHEDULE.trim():
        this.share();
        break;
      case ActivityGridOptionsMenu.CLONE_CRITERIA.trim():
        this.cloneCriteria();
      case ActivityGridOptionsMenu.EDIT_CRITERIA.trim():
        this.editCriteria();
      default:
        break;
    }
  }

  public autoSize() {
    const grid = this.gridParams.gridVisualOptions.apiRef().grid;
    grid.api.sizeColumnsToFit();
    setTimeout(() => grid.api.autoSizeAllColumns(), 150);
  }

  public redrawRows() {
    const grid = this.gridParams?.gridVisualOptions?.apiRef()?.grid;
    if(grid) {
      grid.api.redrawRows();
    }
  }

  public fitColumnsToScreen() {
    const grid = this.gridParams.gridVisualOptions.apiRef().grid;
    grid.api.sizeColumnsToFit();
  }

  public exportSheetCSV() {
    this.affinitiesService.exportSheetAsCSV(this.reportActivity, this.gridParams.gridVisualOptions);
  }

  public exportSheetXLSX() {
    this.affinitiesService.exportSheetAsExcel(this.reportActivity, this.gridParams.gridVisualOptions);
  }

  gridFeatureUsed(feature: string) {
    this.mixpanelService.track(MixpanelEvent.FEATURE_SELECTED, {
      'Application': this.config.getApplication().display,
      'Feature': feature,
    });
  }

  public share() {
    this.sharingService.share(this.reportActivity);
  }

  public cloneCriteria() {
    this.affinitiesService.cloneReport(this.reportActivity.getId());
  }

  public editCriteria() {

  }

  private poll() {

    of(true)
      .pipe(
        delay(this.config.pollInterval),
        takeUntil(this.unsub$),
        switchMap(_ =>
          this.affinitiesService.getReport(this.reportId) // Retrieve report
            .pipe(
              distinctUntilChanged(),
              finalize(() => {
                // stop progress bar
                EmitterService.get('topLoading').emit(false);
              })
            )
        ),
      )
      .subscribe(a => {
        this.reportActivity = a;
        this.status = this.reportActivity.getStatus();
        this.applicationName = this.reportActivity.getName();
        this.checkStatus();
      }, error => {
        // stop progress bar
        EmitterService.get('topLoading').emit(false);
        // redirect to non-existent url to be get by NotFoundModule module
        this.router.navigate([this.NOT_FOUND], {relativeTo: this.route}).then(value => {
        // replace non-existent url by the old one
        this.location.replaceState(this.router.url.toString().replace('/' + this.NOT_FOUND, ''));
        });
      });
  }

  private checkStatus() {
    this.status = this.reportActivity.getStatus();
    switch (this.status) {
      case ActivityStatus.ERROR:
      case ActivityStatus.ALERT:
        this.showSpinner = false;
        break;
      case ActivityStatus.READY:
        this.init(this.reportActivity);
        this.processReport();
        this.showSpinner = false;
        this.ready();
        break;
      case ActivityStatus.EXPIRED:
      case ActivityStatus.RUNNING:
        this.poll();
        break;
    }
  }
}
