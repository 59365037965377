import { Injectable } from '@angular/core';
import { SiqHttpService } from 'app/core/services/siq-http/siq-http.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Activity } from 'app/activity/models/activity.model';
import { IncrementalsConfig } from 'app/siq-applications/modules/incrementals/models/incrementals-config.model';
import { HttpClient } from '@angular/common/http';
import { ActivityResultType, ActivityService } from 'app/activity/services/activity.service';
import { MixpanelService } from 'app/core/services/mixpanel/mixpanel.service';
import { DatesService } from 'app/siq-forms/modules/dates/services/dates.service';
import { ApplicationHash, WeekEndingDay } from '@siq-js/core-lib';
import {
  NotificationService,
  NotificationType,
  ResponseCode,
  ResponseCodes,
  ResponseCodesConfig
} from '@siq-js/angular-buildable-lib';
import { CmsService } from 'app/core/services/cms/cms.service';
import { CmsField } from '@siq-js/cms-lib';
import { FilterSelection } from 'app/filter/models/filter-selection';
import { IncrementalsFormJson, IncrementalsFormData } from 'app/siq-applications/modules/incrementals/models/form/incrementals-form-data.model';
import { IncrementalsParameters } from 'app/siq-applications/modules/incrementals/models/form/incrementals-parameters';
import { map, tap } from 'rxjs';
import { UtilsService } from 'app/core/services/utils/utils.service';
import { EnvConfigService } from 'app/core/services/env-config/env-config.service';
import { AppRequest } from 'app/siq-applications/modules/shared/models/app-request.model';
import { AppDataset } from 'app/siq-applications/modules/shared/models/app-dataset.model';
import { MixpanelEvent } from 'app/core/services/mixpanel/mixpanel-event.enum';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { IncrementalsActivity } from 'app/siq-applications/modules/incrementals/models/incrementals-activity.model';
import { ActivityFactory } from 'app/activity/models/activity.factory';
import { ReportBuilderService } from 'app/siq-applications/modules/report-builder/services/report-builder.service';
import { ExcelService, IStatResponse, VisualOptions } from '@siq-js/visual-lib';
import { DateRangeInterfaceType } from 'app/siq-forms/modules/dates/models/interfaces';

@Injectable()

export class IncrementalsService extends SiqHttpService {
  public static Activities$: BehaviorSubject<IncrementalsActivity[]>;
  public static readonly apiPath = 'app/incremental';
  public clonedActivity: Activity;

  private overrideCodes: ResponseCode[] = [];

  constructor(
    protected http: HttpClient,
    protected notificationService: NotificationService,
    private activityService: ActivityService,
    private config: IncrementalsConfig,
    private mixpanelService: MixpanelService,
    private router: Router
  ) {
    super(http, notificationService);
    if (!IncrementalsService.Activities$) {
      IncrementalsService.Activities$ = ActivityService.createStream<IncrementalsActivity>(
        activities => activities.filter(a => a.getAppId() === ApplicationHash.INCREMENTALS && (!a.isMine() || (a.isMine() && !a.isSharedOrScheduled()))).map(a => a as IncrementalsActivity)
      );
    }
  }

    // Clone an affinities form using formValues from an activity
    public cloneReport(id: string) {
      this.getReport(id)
        .subscribe(activity => {
          this.clonedActivity = activity;
          this.router.navigate(['/app/assortment/~']);
        });
    }

  /**
   * Create IncrementalsFormData
   * Optional field json can be passed in - allowing it to be repopulated from the formValues field of the report
   * If no json passed in, create a blank formData
   * @param fv: IncrementalsFormJson used to create IncrementalsFormData
   */
  public createForm(fv?: IncrementalsFormJson): IncrementalsFormData {
    const formData = new IncrementalsFormData();
    const cmsConfig = CmsService.get();

    if (fv) {
      formData.name = fv.name;
      formData.schema = fv.schema;
      formData.dateRanges = DatesService.isDynamic(fv.dateRanges)
        ?
        {
          end: new Date(DatesService.dateStoMS(fv.dateRanges.end)),
          begin: new Date(DatesService.dateStoMS(fv.dateRanges.begin)),
          dynamicBegin: fv.dateRanges.dynamicBegin,
          dynamicEnd: fv.dateRanges.dynamicEnd,
        }
        :
        {
          end: new Date(DatesService.dateStoMS(fv.dateRanges.end)),
          begin: new Date(DatesService.dateStoMS(fv.dateRanges.begin))
        };
      formData.dateRanges.type = DateRangeInterfaceType.POPULATED;
      formData.incrementalType = cmsConfig.findEntity<CmsField>(fv.incrementalType.id);
      formData.isCloned = fv.isCloned;
      formData.locationFilters = fv.locationFilters.map(f => new FilterSelection(f));
      formData.productFilters = fv.productFilters.map(f => new FilterSelection(f));
      if (fv.weekEndingDay) {
        formData.weekEndingday = fv.weekEndingDay;
      } else { // If there's no weekEndingDay, means it's an old report created before WE project.
        formData.weekEndingday = WeekEndingDay.OLD_REPORT; // According to ICE-2295: Old reports before WE project used Saturday as default WE. RB TimeBrekDown used Sunday as default.
      }
    }

    return formData;
  }

  /**
   * Sends the POST request to create a report
   */
  public createReport(formData: IncrementalsFormData): Observable<any> {
    this.overrideCodesForCreateRequests(formData);

    const params: IncrementalsParameters = {
      incrementalType: UtilsService.paramify(formData.incrementalType),
      filters: [
        ...formData.locationFilters.map(f => UtilsService.paramify(f)),
        ...formData.productFilters.map(f => UtilsService.paramify(f))
      ],
      dateRanges: [DatesService.paramify(formData.dateRanges, formData.weekEndingday)]
    };

    // add retailer filter in DTO if in single retailer analysis mode
    if (formData.schema !== EnvConfigService.getConfig().primaryEntity) {
      const model = new FilterSelection({
        id: CmsService.RETAILER_FIELD_ID,
        values: [formData.schema],
        include: true,
        nulls: false
      });
      params.filters.unshift(UtilsService.paramify(model));
    }

    // if name is not set(undefined), use default value
    if (_.isNil(formData.name)) {
      formData.name = Activity.ActivityPlaceholder;
    }

    let metaData = new Map<string, string>();
    metaData.set('name', formData.name);
    metaData.set('analysisType', formData.getAnalysisType());

    const payload: AppRequest = new AppRequest(
      [
        new AppDataset(`${formData.incrementalType.display} Incremental`, params)
      ],
      formData.toJson(),
      metaData
    );

    return this.create({ endpoint: IncrementalsService.apiPath, body: payload.asJsonObject() })
      .pipe(
        map(res => res.body),
        tap(res => {
          ActivityService.refresh();
          this.mixpanelService.track(MixpanelEvent.ACTIVITY_CREATED, {
            'Application': this.config.getApplication().display,
            'Activity ID': res['appActivityId'],
            'JSON': formData.toJson(),
            'Name': formData.name,
            'Type': 'Report'
          });
        })
      );
  }

  private overrideCodesForCreateRequests(formData: IncrementalsFormData) {
    if (formData.isCloned) {
      this.overrideResponseCode(
        200,
        NotificationType.SUCCESS,
        'Report Cloned',
        'Your report has been cloned.'
      );
    } else {
      this.overrideResponseCode(
        200,
        NotificationType.SUCCESS,
        'Report Created',
        'Your report has been created and is now running.'
      );
    }
  }

  // Get an activity using id
  public getReport(reportId: string): Observable<IncrementalsActivity> {
    return this.activityService.getActivity<IncrementalsActivity>({
      id: reportId,
      resultType: ActivityResultType.NO_RESULTS
    });
  }

  public getSheet(sheetId: string, reportId: string): Observable<Activity> {
    return this.get({ endpoint: ReportBuilderService.apiPath + '/' + reportId + '/sheet/' + sheetId })
      .pipe(map(res => ActivityFactory.createActivity<Activity>({ activity: res[0] })));
  }

  public overrideResponseCode(code: number, type: NotificationType, header: string, message: string): void {
    _.remove(this.overrideCodes, ['code', code]);
    this.overrideCodes.push(
      new ResponseCode(
        code,
        '<hr/>' + message,
        header,
        type
      )
    );
  }

  public getResponseCodes(responseCodesConfig: ResponseCodesConfig): ResponseCodes {
    return new ResponseCodes(this.overrideCodes);
  }

  /**
   ** IMPORTANT! Currently use NUMBER_SELLING_STORE_WEEKS as denominator to calculate per store week values.
   ** Need to confirm with Kevin or business.
   * Calculate per store week metrics.
   * Make metrics that don't have per store week values consistent(keep the same values between absolute and per store week datasets)
   * According to https://pdisoftware.atlassian.net/browse/ICE-951?focusedCommentId=192282,
   * "Percent store distribution" metric values should be consistent with the truncated dataset and
   * "Store Count" metric values should be consistent with the not truncated dataset.
   * @param dateRanges
   * @param srPerStoreWeek: Per store week stat response
   * @param srAbsolute: Absolute stat response
   */
  public processPerStoreWeek(srPerStoreWeek: IStatResponse, srAbsolute: IStatResponse): IStatResponse {
    const factIndexTypeMap = new Map(); // map to track if a fact at a given index is "Absolute" or "Per store week" fact.
    const absoluteValMap = new Map(); // map to hold the absolute facts values given a dimension index.
    let numSellStoreWeekIdx: number;

    srAbsolute.valuesMatrix.forEach(row => {
      const key = srAbsolute.getDimensionValues()[0][row[0]]; // row[0] holds the dimension index in dimensionMatrix
      absoluteValMap.set(key, row);
    });

    srPerStoreWeek.facts.forEach((m, i) => {
      if (IncrementalsConfig.PER_STORE_WEEK_METRICS.indexOf(m.display) > -1) { // per store week metric
        factIndexTypeMap.set(i, IncrementalsConfig.PER_STORE_WEEK);
      } else if (m.display === IncrementalsConfig.NUMBER_SELLING_STORE_WEEKS) {
        numSellStoreWeekIdx = i;
        factIndexTypeMap.set(i, IncrementalsConfig.NUMBER_SELLING_STORE_WEEKS);
      } else if (m.display === IncrementalsConfig.PERCENT_STORE_DISTRIBUTION) {
        factIndexTypeMap.set(i, IncrementalsConfig.PERCENT_STORE_DISTRIBUTION);
      } else { // metric value is consistent
        factIndexTypeMap.set(i, IncrementalsConfig.ABSOLUTE);
      }
    });

    srPerStoreWeek.valuesMatrix.forEach(row => {
      const key = srPerStoreWeek.getDimensionValues()[0][row[0]];
      const absoluteData = absoluteValMap.get(key);
      for (let i = 1; i < row.length; i++) { // starts from 1 because the first number is for dimensionMatrix
        const type = factIndexTypeMap.get(i - 1); // -1 because factIndexTypeMap tracks fact index starting at 0
        switch (type) {
          case IncrementalsConfig.ABSOLUTE:
            if (absoluteData) {
              row[i] = absoluteData[i]; // make metrics that don't have per store week values consistent with the not truncated dataset
            }
            break;
          case IncrementalsConfig.PER_STORE_WEEK:
            // Calculate per store per week value
            row[i] = (parseFloat(row[i]) / parseFloat(row[numSellStoreWeekIdx + 1])).toString(); // +1 because the first number is for dimensionMatrix
            break;
          case IncrementalsConfig.PERCENT_STORE_DISTRIBUTION:
            if (absoluteData) {
              absoluteData[i] = row[i]; // make "percent store distribution metric value" consistent with the truncated dataset
            }
            break;
        }
      }
    });
    return srPerStoreWeek;
  }

  public exportSheetAsCSV(parentActivity: Activity, gridVisualOptions: VisualOptions) {
    ExcelService.exportSheetAsCSV(parentActivity.getName(), gridVisualOptions);

    this.mixpanelService.track(MixpanelEvent.REPORTS_EXPORTED, {
      'Application': this.config.getApplication().display,
      'Report ID': parentActivity.getId(),
      'Report Name': parentActivity.getName(),
      'File Type': 'csv',
      'Export Type': 'report'
    });
  }

  public exportSheetAsExcel(parentActivity: Activity, gridVisualOptions: VisualOptions) {
    ExcelService.saveSheetAsXLSX(parentActivity.getName(), gridVisualOptions);

    this.mixpanelService.track(MixpanelEvent.REPORTS_EXPORTED, {
      'Application': this.config.getApplication().display,
      'Report ID': parentActivity.getId(),
      'Report Name': parentActivity.getName(),
      'File Type': 'xlsx',
      'Export Type': 'report'
    });
  }
}
