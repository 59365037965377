import { VisualOptions, IStatResponse, GridOptions, GridSettings } from '@siq-js/visual-lib';
import { AffinitiesDrilldownComponent } from 'app/siq-applications/modules/affinities/components/affinities-drilldown/affinities-drilldown.component';
import { GridService } from 'libs/visual-lib/src/lib/modules/grid/services/grid.service';
import { BehaviorSubject } from 'rxjs';
import * as _ from 'lodash';

export interface AffinitiesDrillDownGridParams {
  gridVisualOptions?: VisualOptions;
  parent: AffinitiesDrilldownComponent;
  readyForExport$: BehaviorSubject<boolean>;
  statResponse: IStatResponse;
}

export class AffinitiesDrillDownGridProcessor {
  public static generateGridVisualOptions(gridParams: AffinitiesDrillDownGridParams): VisualOptions {
    const data = GridService.jobToArray(gridParams.statResponse);
    let vo: VisualOptions = GridService.generateDefaultGridVisualOptions(); //needed for async -> initialize allowAsyncTotalsCalls$

    return <VisualOptions>  _.merge(vo,{
      initState: {
        dimensions: gridParams.statResponse.getDimensions().filter(d => !d.isNull()),
        metrics: gridParams.statResponse.getFacts(),
      },
      data: data,
      dimensions: gridParams.statResponse.getDimensions().filter(d => !d.isNull()),
      metrics: gridParams.statResponse.getFacts(),
      parentActivity: gridParams.parent.drillDownActivity,
      statResponse: gridParams.statResponse,
      gridConfig: {
        persistState: true,
        customGridOptions: {
          sideBar: false
        },
        maxColumnsToFit: 2,
        pinLeft: [0],
        defaultSortOptions: {
          byKey: 'TOTAL_QUANTITY',
          sort: 'desc'
        },
        gridReadyForExport$: new BehaviorSubject<boolean>(false)
      }
    });
  }

  public static processor(gridParams: AffinitiesDrillDownGridParams): GridSettings {
    // Generate the GridOptions (overrides) which will get merged to the default GridOptions created by/in GridComponent
    let gridOptions: GridOptions = gridParams.gridVisualOptions.gridConfig.customGridOptions || {};

    const gridSettings: GridSettings = <GridSettings>{
      data: gridParams.gridVisualOptions.data, // set pointer for ease of use/readability later
      gridOptions: gridOptions,
      gridVisualOptions: gridParams.gridVisualOptions,
      parentActivity: gridParams.gridVisualOptions.parentActivity // set pointer for ease of use/readability later
    };

    // To enable AsyncTotals for AffinitiesDrilldown, we just need to uncomment the following line. Everything else is already setup.
    // GridTotalsService.provideAsyncTotalsFunctionality(gridSettings);
    return gridSettings;
  }
}
